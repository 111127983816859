import classNames from 'classnames';
import { FC } from 'react';
import { useId } from '../utils/context';

import './Input.scss';

export type InputProps = {
  type?: string;
  value?: string | number;
  placeholder?: string;
  autoComplete?: string;
  maxLength?: number;
  tabIndex?: number;
  onChange?: (newValue: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  multi?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
  state?: 'error' | 'warning';
  helpText?: string;
};

export const Input: FC<InputProps> = ({
  type,
  value,
  placeholder,
  autoComplete,
  maxLength,
  tabIndex,
  onChange,
  onFocus,
  onBlur,
  multi,
  disabled,
  required,
  readOnly,
  min,
  max,
  state,
  helpText,
}) => {
  const id = useId();

  return (
    <div
      className={classNames('input', {
        'input--multi': multi,
        [`input--${state}`]: state,
      })}
    >
      {multi ? (
        <textarea
          id={id}
          className="input__element"
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          maxLength={maxLength}
          tabIndex={tabIndex}
          onChange={(e) => onChange?.(e.target.value)}
          onFocus={() => onFocus?.()}
          onBlur={() => onBlur?.()}
          rows={10}
          cols={10}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          {...(!autoComplete ? { 'data-1p-ignore': true } : {})}
        />
      ) : (
        <input
          id={id}
          type={type}
          className="input__element"
          value={value}
          placeholder={placeholder}
          autoComplete={autoComplete}
          maxLength={maxLength}
          tabIndex={tabIndex}
          onChange={(e) => onChange?.(e.target.value)}
          onFocus={() => onFocus?.()}
          onBlur={() => onBlur?.()}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          min={min}
          max={max}
          {...(!autoComplete ? { 'data-1p-ignore': true } : {})}
        />
      )}
      {helpText ? <div className="input__help-text">{helpText}</div> : null}
    </div>
  );
};
