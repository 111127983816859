import { FC } from 'react';
import classNames from 'classnames';

import './Spinner.scss';

export type SpinnerProps = {
  variant?: 'inline' | 'block';
};

export const Spinner: FC<SpinnerProps> = ({ variant = 'inline' }) => {
  return (
    <span className={classNames('spinner', `spinner--${variant}`)}>
      <span className="spinner__item spinner__item--1" />
      <span className="spinner__item spinner__item--2" />
      <span className="spinner__item spinner__item--3" />
    </span>
  );
};
