import { FC, Fragment, ReactNode, useMemo } from 'react';
import {
  Dialog,
  DialogElement,
  fireDialogButton,
  setDialogFieldValue,
  uploadDialogFieldFile,
} from '../../services/api/dialogs';
import { Stack } from '../../components/Stack';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { Headline } from '../../components/Headline';
import { FormField } from '../../components/FormField';
import { Cluster } from '../../components/Cluster';
import { DialogSummary } from './DialogSummary';
import {
  isChoiceElementType,
  isFormFieldElementType,
  isReadOnlyElementType,
  isUploadFieldElementType,
} from '../../utils/dialogs';
import { Choice } from '../../components/Choice';
import { Copy } from '../../components/Copy';
import { Comment } from '../../components/Comment';
import { Upload } from '../../components/Upload';

import './DialogForm.scss';

const getFormFieldElements = (dialog: Dialog): DialogElement[] =>
  dialog.elements.filter((element) => isFormFieldElementType(element.type));

const getChoiceElements = (dialog: Dialog): DialogElement[] =>
  dialog.elements.filter((element) => isChoiceElementType(element.type));

const getUploadFieldElements = (dialog: Dialog): DialogElement[] =>
  dialog.elements.filter((element) => isUploadFieldElementType(element.type));

const getReadOnlyElements = (dialog: Dialog): DialogElement[] =>
  dialog.elements.filter((element) => isReadOnlyElementType(element.type));

export type DialogFormProps = {
  dialog: Dialog;
  values: Record<string, string | File>;
  isLoading: boolean;
  setIsLoading: (newIsLoading: boolean) => void;
  onChangeValue: (elementId: string, value: string | File) => void;
  onChange: (newDialog: Dialog) => void;
  onRequestClose: () => void;
};

export const DialogForm: FC<DialogFormProps> = ({
  dialog,
  values,
  setIsLoading,
  onChange,
  onChangeValue,
  onRequestClose,
}) => {
  const submitForm = async (triggerElement: DialogElement) => {
    setIsLoading(true);
    try {
      for (const element of getFormFieldElements(dialog)) {
        await setDialogFieldValue({
          dialogId: dialog.id,
          elementId: element.elementId,
          value: String(values[element.elementId]),
        });
      }

      for (const element of getUploadFieldElements(dialog)) {
        const value = values[element.elementId];
        if (value instanceof File) {
          await uploadDialogFieldFile({
            dialogId: dialog.id,
            elementId: element.elementId,
            file: value,
          });
        }
      }

      const newDialog = await fireDialogButton({
        dialogId: dialog.id,
        elementId: triggerElement.elementId,
      });
      onChange(newDialog);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderElement = (element: DialogElement): ReactNode => {
    switch (element.type) {
      case 'DialogHeadline':
        return <Headline>{element.name}</Headline>;
      case 'DialogSubline':
        return <Copy>{element.name}</Copy>;
      case 'DialogTextField':
        return (
          <FormField id={element.elementId}>
            <Input
              placeholder={element.name}
              value={String(values[element.elementId] ?? '')}
              onChange={(newValue) =>
                onChangeValue(element.elementId, newValue)
              }
            />
          </FormField>
        );
      case 'DialogButton':
      case 'DialogBlätternWeiter':
      case 'DialogBlätternZurück':
      case 'DialogButtonSenden':
        return (
          <Button
            label={element.name}
            shortcut={element.name.substr(0, 1)}
            onClick={async () => {
              await submitForm(element);
            }}
          />
        );
      case 'DialogCommentBox':
        return (
          <Comment
            title={element.name}
            comments={element.comments ?? []}
            author={{
              name: element.authorName ?? '?',
              imageUrl: element.authorImage,
            }}
          />
        );
      case 'DialogFileUpload':
        const value = values[element.elementId];
        return (
          <FormField id={element.elementId}>
            <Upload
              placeholder={element.name}
              value={value instanceof File ? value : undefined}
              onChange={(files) => {
                onChangeValue(element.elementId, files[0]);
              }}
            />
          </FormField>
        );

      case 'DialogFileDownload':
        return <>DialogFileDownload</>;
      case 'DialogChoice':
        return (
          <Choice
            label={element.name}
            shortcut={element.name.substr(0, 1)}
            onClick={async () => {
              await submitForm(element);
            }}
          />
        );
      case 'DialogDatePicker':
        return <>DialogDatePicker</>;
      case 'DialogPersonPicker':
        return <>DialogPersonPicker</>;
      case 'DialogButtonSchließen':
        return (
          <Button
            label={element.name}
            shortcut={element.name.substr(0, 1)}
            onClick={async () => {
              onRequestClose();
            }}
          />
        );
      default:
        return <div>Unbekanntes Dialog-Element »{element.type}«</div>;
    }
  };
  const isSummaryStep = useMemo(
    () =>
      getFormFieldElements(dialog).length === 0 &&
      getChoiceElements(dialog).length === 0 &&
      getReadOnlyElements(dialog).length === 0,
    [dialog],
  );

  const left = useMemo(
    () =>
      dialog.elements.filter(
        (element) =>
          element.type === 'DialogCommentBox' &&
          (!element.alignment || element.alignment === 'LEFT'),
      ),
    [dialog],
  );
  const right = useMemo(
    () =>
      dialog.elements.filter(
        (element) =>
          element.type === 'DialogCommentBox' && element.alignment === 'RIGHT',
      ),
    [dialog],
  );
  const elements = useMemo(
    () =>
      dialog.elements.filter((element) => element.type !== 'DialogCommentBox'),
    [dialog],
  );

  return (
    <div className="dialog-form">
      {left.length ? (
        <div className="dialog-form__left">
          {left.map((element) => (
            <Fragment key={element.objectId}>{renderElement(element)}</Fragment>
          ))}
        </div>
      ) : null}
      <div className="dialog-form__main">
        <Stack>
          {isSummaryStep ? (
            <DialogSummary dialog={dialog} />
          ) : (
            elements.map((element) => (
              <Fragment key={element.objectId}>
                {renderElement(element)}
              </Fragment>
            ))
          )}
          <Cluster>
            {dialog.buttons.map((button) => (
              <Fragment key={button.objectId}>{renderElement(button)}</Fragment>
            ))}
          </Cluster>
        </Stack>
      </div>
      {right.length ? (
        <div className="dialog-form__right">
          {right.map((element) => (
            <Fragment key={element.objectId}>{renderElement(element)}</Fragment>
          ))}
        </div>
      ) : null}
    </div>
  );
};
