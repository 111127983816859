import { get } from '../request';
import { ApiResponse, WithIds, isErrorResponse } from './common';
import { ApiCompany } from './model';

export type ApiWorkflow = WithIds<{
  thema: string;
  name: string;
  workflowId: string;
  vertragsbeziehung: WithIds<{
    name: string;
    partei1: ApiCompany;
    partei1Id: string;
    partei2: ApiCompany;
    partei2Id: string;
    vertragsbeziehungsTyp: 'WERKVERTRAG';
    vertraege: WithIds<{
      name: string;
      vertragsTyp: string;
      vertragsbestimmungen: WithIds<{
        name: string;
        leistungsbeschreibung: string | null;
      }>[];
      vertragsbestimmungenIds: string[];
    }>[];
    vertraegeIds: string[];
  }>;
  parteiTyp: string;
  eintraege: never[];
}>;

export type WorkflowResponse = ApiWorkflow[];

export type Workflow = {
  id: string;
  objectId: string;
  label: string;
};

export type GetWorkflowsProps = {
  contractRelationId: string;
};

export const getWorkflows = async (
  props: GetWorkflowsProps,
): Promise<Workflow[]> => {
  const result = await get<ApiResponse<WorkflowResponse>>(
    '/dialog/getWorkflows',
    {
      searchParams: new URLSearchParams({
        vertragsbeziehungId: props.contractRelationId,
      }),
    },
  );

  if (isErrorResponse(result)) {
    return [];
  }

  return result.map((workflow) => ({
    id: workflow.workflowId,
    objectId: workflow._idAsString,
    label: `${workflow.thema} (${workflow.name})`,
  }));
};
