import { FC, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../utils/auth';
import {
  ContractRelation,
  getContractRelations,
} from '../../services/api/contracts';
import { Spinner } from '../../components/Spinner';
import { Workflow, getWorkflows } from '../../services/api/workflows';
import { DialogForm } from './DialogForm';
import { WorkflowForm } from './WorkflowForm';
import { ContractRelationForm } from './ContractRelationForm';
import { Dialog, startDialog } from '../../services/api/dialogs';
import { Modal } from '../../components/Modal';

export type MessageWizardProps = {
  onRequestClose: () => void;
};

export const MessageWizard: FC<MessageWizardProps> = ({ onRequestClose }) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [contractRelations, setContractRelations] = useState<
    ContractRelation[]
  >([]);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [currentContractRelationId, setCurrentContractRelationId] = useState<
    string | undefined
  >(undefined);
  const [selectedContractRelationId, setSelectedContractRelationId] = useState<
    string | undefined
  >(undefined);
  const [currentWorkflowId, setCurrentWorkflowId] = useState<
    string | undefined
  >(undefined);
  const selectedContractRelation = useMemo(
    () =>
      contractRelations.find((item) => item.id === selectedContractRelationId),
    [selectedContractRelationId, contractRelations],
  );
  const currentWorkflow = useMemo(
    () => workflows.find((item) => item.id === currentWorkflowId),
    [currentWorkflowId, workflows],
  );
  const [description, setDescription] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [currentDialog, setCurrentDialog] = useState<Dialog | undefined>(
    undefined,
  );
  const [values, setValues] = useState<Record<string, string | File>>({});

  useEffect(() => {
    (async () => {
      if (user?.companyId) {
        setContractRelations(
          await getContractRelations({
            companyId: user.companyId,
          }),
        );
      }
      setIsLoading(false);
    })();
  }, [user?.companyId]);

  useEffect(() => {
    (async () => {
      if (selectedContractRelationId) {
        setWorkflows(
          await getWorkflows({
            contractRelationId: selectedContractRelationId,
          }),
        );
      }
      setIsLoading(false);
    })();
  }, [selectedContractRelationId]);

  return (
    <Modal
      id="add-message"
      variant="condensed"
      title={
        currentWorkflow
          ? `${currentWorkflow.label} für »${selectedContractRelation?.label}«`
          : selectedContractRelation
          ? `Neue Korrespondenz für »${selectedContractRelation.label}«`
          : 'Neue Korrespondenz'
      }
      onClose={() => onRequestClose()}
    >
      {isLoading ? (
        <Spinner variant="block" />
      ) : currentDialog ? (
        <DialogForm
          dialog={currentDialog}
          values={values}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onChange={(newDialog) => setCurrentDialog(newDialog)}
          onChangeValue={(elementId, value) => {
            setValues((currentValues) => {
              return {
                ...currentValues,
                [elementId]: value,
              };
            });
          }}
          onRequestClose={() => onRequestClose()}
        />
      ) : selectedContractRelationId ? (
        <WorkflowForm
          currentWorkflowId={currentWorkflowId}
          setCurrentWorkflowId={setCurrentWorkflowId}
          workflows={workflows}
          description={description}
          setDescription={setDescription}
          summary={summary}
          setSummary={setSummary}
          onSubmit={async () => {
            if (currentWorkflow && selectedContractRelationId) {
              setIsLoading(true);
              const newDialog = await startDialog({
                workflowId: currentWorkflow.id,
                workflowObjectId: currentWorkflow.objectId,
                contractRelationId: selectedContractRelationId,
                description,
                summary,
              });
              setCurrentDialog(newDialog);
              setIsLoading(false);
            }
          }}
        />
      ) : (
        <ContractRelationForm
          currentContractRelationId={currentContractRelationId}
          setCurrentContractRelationId={setCurrentContractRelationId}
          contractRelations={contractRelations}
          onSubmit={() => {
            setSelectedContractRelationId(currentContractRelationId);
          }}
        />
      )}
    </Modal>
  );
};
