import { FC, useMemo } from 'react';
import { WorkflowBuilderInternalWorkflowDialogElement } from './types';
import { VisualizerDivider } from './VisualizerDivider';
import { useWorkflow } from '../../../utils/context/workflow';
import { bus } from '../../../utils/bus';
import { waitFor } from '../../../utils/helpers';

import './VisualizerDialogElement.scss';
import { VisualizerDialog } from './VisualizerDialog';

export type VisualizerDialogElementProps = {
  element: WorkflowBuilderInternalWorkflowDialogElement;
};

export const VisualizerDialogElement: FC<VisualizerDialogElementProps> = ({
  element,
}) => {
  const workflow = useWorkflow();
  const nextDialogId = element.to;
  const nextStep = useMemo(
    () =>
      nextDialogId
        ? workflow.steps.find((step) =>
            step.parties.some((party) =>
              party.dialogs.some(
                (dialogItem) =>
                  dialogItem.id !== element.id &&
                  dialogItem.id === nextDialogId,
              ),
            ),
          )
        : undefined,
    [element.id, nextDialogId, workflow.steps],
  );
  const nextParty = useMemo(
    () =>
      nextDialogId && nextStep
        ? nextStep.parties.find((party) =>
            party.dialogs.some(
              (dialogItem) =>
                dialogItem.id !== element.id && dialogItem.id === nextDialogId,
            ),
          )
        : undefined,
    [element.id, nextDialogId, nextStep],
  );
  const nextDialog = useMemo(
    () =>
      nextDialogId && nextParty
        ? nextParty.dialogs.find(
            (dialogItem) =>
              dialogItem.id !== element.id && dialogItem.id === nextDialogId,
          )
        : undefined,
    [element.id, nextDialogId, nextParty],
  );
  const step = useMemo(
    () =>
      workflow.steps.find((step) =>
        step.parties.some((partyItem) =>
          partyItem.dialogs.some((dialogItem) =>
            dialogItem.elements.some(
              (elementItem) => elementItem.id === element.id,
            ),
          ),
        ),
      ),
    [element.id, workflow.steps],
  );
  const party = useMemo(
    () =>
      step?.parties.find((partyItem) =>
        partyItem.dialogs.some((dialogItem) =>
          dialogItem.elements.some(
            (elementItem) => elementItem.id === element.id,
          ),
        ),
      ),
    [element.id, step?.parties],
  );
  const dialog = useMemo(
    () =>
      party?.dialogs.find((dialogItem) =>
        dialogItem.elements.some(
          (elementItem) => elementItem.id === element.id,
        ),
      ),
    [element.id, party?.dialogs],
  );

  return (
    <div
      className="visualizer-dialog-element"
      title={`${step?.name} / ${party?.name} / ${dialog?.name} / ${element.name}`}
    >
      <button
        className="visualizer-dialog-element__shape"
        onClick={async () => {
          bus.emit('workflow-builder:step', {
            stepId: step?.id,
          });
          await waitFor(50);
          bus.emit('workflow-builder:party', {
            partyId: party?.id,
          });
          await waitFor(50);
          bus.emit('workflow-builder:dialog', {
            dialogId: dialog?.id,
          });
          await waitFor(50);
          bus.emit('workflow-builder:element', {
            elementId: element.id,
          });
        }}
      >
        {element.name}
      </button>
      {nextStep ? (
        <div className="visualizer-dialog-element__reference">
          <VisualizerDivider size="large" />
          {nextDialog && element.id === nextDialog.id ? (
            <div className="visualizer-dialog-element__end">
              ⭍ Zirkelbezug ⭍<br />
              {nextStep.name} / {nextParty?.name} / {nextDialog?.name}
            </div>
          ) : nextStep && nextParty && nextDialog ? (
            <VisualizerDialog dialog={nextDialog} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
