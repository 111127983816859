import { useEffect, useRef } from 'react';
import { DraggableChildrenFn } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';

export type DraggableInPortalRenderResult = (
  render: DraggableChildrenFn,
) => DraggableChildrenFn;

/** @see https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-669083882 */
export const useDraggableInPortal = (): DraggableInPortalRenderResult => {
  const self = useRef<{
    element?: Element;
  }>({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    div.style.zIndex = '123456';
    self.element = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return (render) =>
    (provided, ...args) => {
      const element = render(provided, ...args);
      if (
        self.element &&
        provided.draggableProps.style &&
        'position' in provided.draggableProps.style &&
        provided.draggableProps.style.position === 'fixed'
      ) {
        return createPortal(element, self.element);
      }
      return element;
    };
};
