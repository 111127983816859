import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { ActionButton } from './ActionButton';
import { lockBodyScrolling, unlockBodyScrolling } from '../utils/scroll';

import './Modal.scss';

export type ModalVariant = 'default' | 'condensed';

export type ModalProps = PropsWithChildren<{
  id: string;
  title?: ReactNode;
  variant?: ModalVariant;
  onClose: () => void;
}>;

export const Modal: FC<ModalProps> = ({
  id,
  title,
  variant = 'default',
  onClose,
  children,
}) => {
  useEffect(() => {
    lockBodyScrolling(id);
    return () => unlockBodyScrolling(id);
  }, [id]);

  return (
    <div
      className={classNames('modal', {
        [`modal--${variant}`]: variant,
      })}
    >
      <div className="modal__backdrop" onClick={() => onClose()} />
      <div className="modal__panel">
        <div className="modal__header">
          <div className="modal__title">{title || null}</div>
          <div className="modal__actions">
            <ActionButton
              variant="subtle"
              icon="Cross"
              onClick={() => onClose()}
            />
          </div>
        </div>
        <div className="modal__content">
          <div className="modal__slot">{children}</div>
        </div>
      </div>
    </div>
  );
};
