import { FC } from 'react';
import classNames from 'classnames';
import { useDayzed } from 'dayzed';
import { format, isWeekend } from 'date-fns';
import { ActionButton } from './ActionButton';
import { Cluster } from './Cluster';
import { Spacer } from './Spacer';
import { Stack } from './Stack';
import { Flame, IconName, getIcon } from './Icon';
import { FIRST_DAY_OF_WEEK, MONTH_NAMES, WEEKDAY_NAMES } from '../utils/ui';

import './Calendar.scss';

export type CalendarDataItem = {
  id: string;
  label: string;
};

export type CalendarData = {
  weather: IconName;
  events?: boolean;
  items: CalendarDataItem[];
};

export type CalendarProps = {
  date?: Date;
  data?: Record<string, CalendarData | undefined>;
  onDateSelected?: (date: Date) => void;
};

export const Calendar: FC<CalendarProps> = ({
  date: base = new Date(),
  data: blaaa,
  onDateSelected: onDateSelect,
}) => {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    firstDayOfWeek: FIRST_DAY_OF_WEEK,
    selected: base,
    onDateSelected: ({ date: newDate }) => {
      onDateSelect?.(newDate);
    },
  });

  return (
    <div className="calendar">
      {calendars.map((calendar) => {
        const backProps = getBackProps({ calendars });
        const forwardProps = getForwardProps({ calendars });
        return (
          <Stack key={`${calendar.month}${calendar.year}`} gap="xl">
            <Cluster stretched y="center">
              <Cluster gap="xs">
                <span className="calendar__month">
                  {MONTH_NAMES[calendar.month]}
                </span>{' '}
                <span className="calendar__year">{calendar.year}</span>
              </Cluster>
              <Spacer />
              <Cluster>
                <ActionButton
                  icon="ChevronLeft"
                  variant="ghost"
                  onClick={backProps.onClick}
                />
                <ActionButton
                  icon="ChevronRight"
                  variant="ghost"
                  onClick={forwardProps.onClick}
                />
              </Cluster>
            </Cluster>
            <Stack gap="xs">
              <div className="calendar__legends">
                {WEEKDAY_NAMES.map((weekday) => (
                  <div
                    key={`${calendar.month}${calendar.year}${weekday}`}
                    className="calendar__legend"
                  >
                    {weekday}
                  </div>
                ))}
              </div>
              <div className="calendar__items">
                {calendar.weeks.map((week, weekIndex) =>
                  week.map((dateObj, index) => {
                    const renderKey = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                    if (!dateObj) {
                      return (
                        <div
                          key={renderKey}
                          className="calendar__item calendar__item--placeholder"
                        />
                      );
                    }
                    const { date, selected, selectable, today } = dateObj;
                    const key = format(date, 'yyyy-MM-dd');
                    const dayData = blaaa?.[key];
                    const WeatherIcon = dayData && getIcon(dayData.weather);
                    return (
                      <div
                        key={renderKey}
                        className={classNames('calendar__item', {
                          'calendar__item--selectable':
                            onDateSelect && selectable,
                          'calendar__item--selected': selected,
                          'calendar__item--today': today,
                          'calendar__item--weekend': isWeekend(date),
                        })}
                        {...getDateProps({ dateObj })}
                      >
                        <Stack stretched>
                          <Cluster gap="xs" stretched>
                            {WeatherIcon ? (
                              <div className="calendar__weather">
                                <WeatherIcon size={16} />
                              </div>
                            ) : null}
                            {dayData?.events ? (
                              <div className="calendar__events">
                                <Flame size={16} />
                              </div>
                            ) : null}
                            <Spacer />
                            <div className="calendar__label">
                              {format(date, 'd')}
                            </div>
                          </Cluster>
                          {dayData?.items.length ? (
                            <Stack gap="2xs">
                              {dayData.items.map((item) => (
                                <div key={item.id} className="calendar__entry">
                                  {item.label}
                                </div>
                              ))}
                            </Stack>
                          ) : null}
                        </Stack>
                      </div>
                    );
                  }),
                )}
              </div>
            </Stack>
          </Stack>
        );
      })}
    </div>
  );
};
