import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import './Preview.scss';

export type PreviewProps = PropsWithChildren<{
  variant?: 'dots' | 'background';
}>;

export const Preview: FC<PreviewProps> = ({ variant = 'dots', children }) => (
  <div className={classNames('preview', `preview--${variant}`)}>
    <div className="preview__flag">Vorschau</div>
    <div className="preview__content">{children}</div>
  </div>
);
