import fetchBuilder from 'fetch-retry';
import { getToken } from './auth';

const fetch = fetchBuilder(window.fetch, {
  retries: 5,
  retryDelay: (attempt: number) => Math.pow(2, attempt) * 1000,
});

export type RequestOptions = {
  method?: RequestInit['method'];
  data?: any;
  searchParams?: URLSearchParams;
};

const request = async <T>(
  passedUrl: string,
  options?: RequestOptions,
): Promise<T> => {
  const headers: RequestInit['headers'] = {};
  if (options?.data instanceof URLSearchParams) {
    headers['content-type'] = 'application/x-www-form-urlencoded';
  }
  if (!(options?.data instanceof FormData)) {
    headers['content-type'] = 'application/json';
  }

  const token = getToken();
  let body: string | URLSearchParams | FormData | undefined = options?.data
    ? options.data instanceof URLSearchParams ||
      options.data instanceof FormData
      ? options.data
      : JSON.stringify(options?.data)
    : undefined;
  if (body instanceof URLSearchParams) {
    body.set('token', token ?? '');
  } else if (!body && options?.method?.toLowerCase() === 'post') {
    body = new URLSearchParams();
    body.set('token', token ?? '');
  }
  const url = new URL(`${process.env.REACT_APP_API_ENDPOINT}${passedUrl}`);
  if (token) {
    url.searchParams.set('token', token);
  }
  if (options?.searchParams) {
    options.searchParams.forEach((value, key) => {
      url.searchParams.set(key, value);
    });
  }
  const response = await fetch(url.toString(), {
    method: options?.method,
    headers,
    body,
    mode: 'cors',
  });
  if (!response.ok) {
    return Promise.reject(await response.json());
  }
  if (response.status === 204) {
    return {} as T;
  }
  const result = (await response.json()) as T;
  return result;
};

export const get = async <T>(
  url: string,
  options?: Omit<RequestOptions, 'method'>,
) =>
  request<T>(url, {
    method: 'GET',
    ...(options ?? {}),
  });

export const post = async <T>(
  url: string,
  options?: Omit<RequestOptions, 'method'>,
) =>
  request<T>(url, {
    method: 'POST',
    ...(options ?? {}),
  });

export const put = async <T>(
  url: string,
  options?: Omit<RequestOptions, 'method'>,
) =>
  request<T>(url, {
    method: 'PUT',
    ...(options ?? {}),
  });

export const del = async <T>(
  url: string,
  options?: Omit<RequestOptions, 'method'>,
) =>
  request<T>(url, {
    method: 'DELETE',
    ...(options ?? {}),
  });
