import { FC, useEffect, useMemo, useState } from 'react';
import { Skeleton } from '../components/Skeleton';
import { Headline } from '../components/Headline';
import { Navigation } from '../fragments/Navigation';
import { Cockpit } from '../fragments/Cockpit';
import { Timeline, TimelineItemProps } from '../components/Timeline';
import { Copy } from '../components/Copy';
import { Stack } from '../components/Stack';
import { getEvents, Event } from '../services/api';
import { Spinner } from '../components/Spinner';

const convertEventToTimelineItem = (event: Event): TimelineItemProps => ({
  id: event.id,
  label: event.label,
  startDate: event.startDate,
  endDate: event.endDate,
  items: (event.items ?? []).map(convertEventToTimelineItem),
});

export const Tracking: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<Event[]>([]);
  const timelineEvents = useMemo(
    () => events.map((event) => convertEventToTimelineItem(event)),
    [events],
  );

  useEffect(() => {
    (async () => {
      setEvents(await getEvents());
      setIsLoading(false);
    })();
  }, []);

  return (
    <Skeleton
      colliding
      intro={
        <Stack>
          <Headline>Echtzeit Bauablauf</Headline>
          <Copy>
            Übersicht und Aktivität über alle Vorgänge und Korrespondenzen
          </Copy>
        </Stack>
      }
      navigation={<Navigation />}
      cockpit={<Cockpit />}
      main={
        isLoading ? (
          <Spinner variant="block" />
        ) : (
          <Timeline items={timelineEvents} now={new Date()} />
        )
      }
    />
  );
};
