import { FC } from 'react';
import { VisualizerStep } from './VisualizerStep';
import { useWorkflow } from '../../../utils/context/workflow';

import './Visualizer.scss';

export const Visualizer: FC = () => {
  const workflow = useWorkflow();

  return workflow.steps.length ? (
    <div className="visualizer">
      <VisualizerStep step={workflow.steps[0]} />
    </div>
  ) : null;
};
