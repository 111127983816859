import { FC } from 'react';
import { Login } from '../fragments/Login';
import { useAuth } from '../utils/auth';

export type AuthWallProps = {
  element: JSX.Element;
  admin?: boolean;
};

export const AuthWall: FC<AuthWallProps> = ({ element, admin }) => {
  const auth = useAuth();

  return auth.token ? element : <Login />;
};
