import { FC, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  WorkflowBuilderInternalWorkflowDialog,
  WorkflowBuilderInternalWorkflowDialogElement,
} from './types';
import { Stack } from '../../../components/Stack';
import { FormField } from '../../../components/FormField';
import { Input } from '../../../components/Input';
import {
  ApiDialogElementType,
  DIALOG_ELEMENT_TYPES,
  isValidApiDialogElementType,
} from '../../../services/api/dialogs';
import { Select } from '../../../components/Select';
import { useWorkflow } from '../../../utils/context/workflow';
import { DialogElementPreview } from './DialogElementPreview';
import { Preview } from './Preview';
import { COMMENT_DIVIDER, COMMENT_ALIGNMENTS } from '../../../utils/internal';
import { isLinkingElement } from '../../../utils/dialogs';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

type ConsolidatedWorkflowBuilderInternalWorkflowDialog = {
  id: string;
  name: string;
  party: string;
  step: string;
};

export type DialogElementEditorProps = {
  item?: WorkflowBuilderInternalWorkflowDialogElement;
  dialog?: WorkflowBuilderInternalWorkflowDialog;
  onSave: (newItem: WorkflowBuilderInternalWorkflowDialogElement) => void;
};

export const DialogElementEditor: FC<DialogElementEditorProps> = ({
  item,
  dialog,
  onSave,
}) => {
  const workflow = useWorkflow();
  const dialogs: ConsolidatedWorkflowBuilderInternalWorkflowDialog[] =
    useMemo(() => {
      const results: ConsolidatedWorkflowBuilderInternalWorkflowDialog[] = [];
      (workflow.steps ?? []).forEach((step) => {
        step.parties.forEach((party) => {
          party.dialogs.forEach((dialogItem) => {
            if (!dialog || (dialog && dialogItem.id !== dialog?.id)) {
              results.push({
                id: dialogItem.id,
                name: dialogItem.name,
                party: party.name,
                step: step.name,
              });
            }
          });
        });
      });
      return results;
    }, [dialog, workflow.steps]);
  const [type, setType] = useState<ApiDialogElementType>(
    item?.type ?? 'DialogHeadline',
  );
  const [name, setName] = useState<string>(item?.name ?? '');
  const [text, setText] = useState<string>(item?.text ?? '');
  const [to, setTo] = useState<string | undefined>(item?.to);
  const [alignment, setAlignment] = useState<string>(item?.alignment ?? 'left');
  const [comment, setComment] = useState<string>(
    (item?.comment ?? [])
      .map((line) => line.trim())
      .filter(Boolean)
      .join(COMMENT_DIVIDER),
  );
  const [authorName, setAuthorName] = useState<string>(item?.authorImage ?? '');
  const [authorImage, setAuthorImage] = useState<string>(
    item?.authorName ?? '',
  );
  const composedElement: WorkflowBuilderInternalWorkflowDialogElement = useMemo(
    () => ({
      id: item?.id ?? nanoid(),
      type,
      name,
      text,
      send: type === 'DialogButtonSenden',
      close: type === 'DialogButtonSchließen',
      to,
      alignment: alignment === 'RIGHT' ? 'RIGHT' : 'LEFT',
      comment: comment
        .split(COMMENT_DIVIDER)
        .map((line) => line.trim())
        .filter(Boolean),
      authorName,
      authorImage,
    }),
    [item, alignment, authorImage, authorName, comment, name, text, to, type],
  );

  useDeepCompareEffectNoCheck(() => {
    onSave(composedElement);
  }, [composedElement]);

  return (
    <Stack gap="2xl">
      <form>
        <Stack>
          <FormField id="element_type" label="Typ">
            <Select
              options={DIALOG_ELEMENT_TYPES.map((type) => ({
                value: type,
                label: type,
              }))}
              value={type}
              onChange={(newType) => {
                if (isValidApiDialogElementType(newType)) {
                  setType(newType);
                }
              }}
            />
          </FormField>
          <FormField id="element_name" label="Interner Name">
            <Input value={name} onChange={(newName) => setName(newName)} />
          </FormField>
          <FormField id="element_text" label="Beschriftung">
            <Input value={text} onChange={(newText) => setText(newText)} />
          </FormField>
          {isLinkingElement(type) ? (
            <FormField id="element_to" label="Ziel">
              <Select
                options={dialogs.map((dialog) => ({
                  value: dialog.id,
                  label: `${dialog.step} / ${dialog.party} / ${dialog.name}`,
                }))}
                value={to}
                onChange={(newTo) => setTo(newTo)}
                nullable
              />
            </FormField>
          ) : null}
          {type === 'DialogCommentBox' ? (
            <FormField id="element_alignment" label="Ausrichtung">
              <Select
                options={Object.entries(COMMENT_ALIGNMENTS).map(
                  ([key, label]) => ({
                    value: key,
                    label,
                  }),
                )}
                value={alignment}
                onChange={(newAlignment) => {
                  if (newAlignment) {
                    setAlignment(newAlignment);
                  }
                }}
                nullable
              />
            </FormField>
          ) : null}
          {type === 'DialogCommentBox' ? (
            <FormField id="element_comment" label="Kommentar(e)">
              <Input
                value={comment}
                onChange={(newComment) => setComment(newComment)}
                multi
                helpText="Doppelter Zeilenumbruch (⏎⏎) trennt Kommentare in einzelne Slides"
              />
            </FormField>
          ) : null}
          {type === 'DialogCommentBox' ? (
            <FormField id="element_author_image" label="Autoren-Bild (URL)">
              <Input
                value={authorImage}
                onChange={(newAuthorImage) => setAuthorImage(newAuthorImage)}
              />
            </FormField>
          ) : null}
          {type === 'DialogCommentBox' ? (
            <FormField id="element_author_name" label="Autoren-Name">
              <Input
                value={authorName}
                onChange={(newAuthorName) => setAuthorName(newAuthorName)}
              />
            </FormField>
          ) : null}
        </Stack>
      </form>
      <Preview>
        <DialogElementPreview element={composedElement} />
      </Preview>
    </Stack>
  );
};
