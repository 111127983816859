import { FC, useMemo } from 'react';
import {
  WorkflowBuilderInternalWorkflowDialog,
  WorkflowBuilderInternalWorkflowParty,
} from './types';
import { VisualizerDialog } from './VisualizerDialog';
import {
  VisualizerDivider,
  getVisualizerDividerVariantForIndex,
} from './VisualizerDivider';
import { bus } from '../../../utils/bus';
import { useWorkflow } from '../../../utils/context/workflow';
import { waitFor } from '../../../utils/helpers';

import './VisualizerParty.scss';

export type VisualizerPartyProps = {
  party: WorkflowBuilderInternalWorkflowParty;
  dialog?: WorkflowBuilderInternalWorkflowDialog;
};

export const VisualizerParty: FC<VisualizerPartyProps> = ({
  party,
  dialog,
}) => {
  const workflow = useWorkflow();
  const step = useMemo(
    () =>
      workflow.steps.find((step) =>
        step.parties.some((partyItem) => partyItem.id === party.id),
      ),
    [party.id, workflow.steps],
  );

  return (
    <div className="visualizer-party" title={`${step?.name} / ${party.name}`}>
      <button
        className="visualizer-party__shape"
        onClick={async () => {
          bus.emit('workflow-builder:step', {
            stepId: step?.id,
          });
          await waitFor(50);
          bus.emit('workflow-builder:party', {
            partyId: party.id,
          });
        }}
      >
        {party.name}
      </button>
      <div className="visualizer-party__dialogs">
        {party.dialogs
          .filter((dialogItem) => !dialog || dialogItem.id === dialog.id)
          .map((dialog, index, array) => (
            <div key={dialog.id} className="visualizer-party__dialog">
              <VisualizerDivider
                variant={getVisualizerDividerVariantForIndex(index, array)}
              />
              <VisualizerDialog dialog={dialog} />
            </div>
          ))}
      </div>
    </div>
  );
};
