import { FC, ReactNode, useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { Avatar } from './Avatar';
import { ActionButton } from './ActionButton';
import { getInitialsForName } from '../utils/ui';
import classNames from 'classnames';

import 'keen-slider/keen-slider.min.css';
import './Comment.scss';

export type CommentProps = {
  title: ReactNode;
  comments: string[];
  author: {
    name: string;
    imageUrl?: string;
  };
};

export const Comment: FC<CommentProps> = ({ title, comments, author }) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [ref, slider] = useKeenSlider({
    loop: true,
    slides: {
      perView: 1,
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
  });

  useEffect(() => {
    slider?.current?.update();
  }, [comments, slider]);

  return (
    <div className="comment">
      <div className="comment__title">{title}</div>
      <div ref={ref} className="comment__slides">
        {comments.map((comment, index) => (
          <div key={index} className="keen-slider__slide comment__slide">
            <div className="comment__content">{comment}</div>
            <div className="comment__author">
              {author.imageUrl ? (
                <Avatar imageUrl={author.imageUrl} />
              ) : (
                <Avatar initials={getInitialsForName(author.name)} />
              )}
              <div className="comment__name">{author.name}</div>
              <svg
                className="comment__indicator"
                width="43"
                height="32"
                viewBox="0 0 43 32"
                fill="none"
              >
                <path
                  opacity="0.2"
                  d="M33.9193 0C36.5901 0 38.7712 0.892429 40.4627 2.67729C42.1542 4.46215 43 7.22443 43 10.9641C43 15.1288 41.9317 18.9535 39.795 22.4382C37.6584 26.008 34.8986 29.1952 31.5155 32L26.441 26.6454C28.4886 24.8606 30.1356 23.1607 31.382 21.5458C32.6284 20.0159 33.4296 18.2736 33.7857 16.3187C31.2039 16.2337 29.2008 15.4263 27.7764 13.8964C26.352 12.4515 25.6398 10.5392 25.6398 8.15936C25.6398 5.69455 26.441 3.69721 28.0435 2.16733C29.646 0.722443 31.6046 0 33.9193 0ZM8.2795 0C10.9503 0 13.1315 0.892429 14.823 2.67729C16.5145 4.46215 17.3602 7.22443 17.3602 10.9641C17.3602 15.1288 16.2919 18.9535 14.1553 22.4382C12.0186 26.008 9.2588 29.1952 5.87577 32L0.801239 26.6454C2.84886 24.8606 4.49586 23.1607 5.74223 21.5458C6.98861 20.0159 7.78985 18.2736 8.14596 16.3187C5.56418 16.2337 3.56108 15.4263 2.13665 13.8964C0.712215 12.4515 0 10.5392 0 8.15936C0 5.69455 0.801239 3.69721 2.40372 2.16733C4.00621 0.722443 5.9648 0 8.2795 0Z"
                  fill="#AEC0D5"
                />
              </svg>
            </div>
          </div>
        ))}
      </div>

      {comments.length > 0 ? (
        <div className="comment__pagination">
          <div className="comment__pages">
            {comments.map((_, index) => (
              <button
                key={index}
                className={classNames('comment__page', {
                  'comment__page--current': index === currentSlide,
                })}
                onClick={() => slider?.current?.moveToIdx(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <div className="comment__actions">
            <ActionButton
              size="sm"
              variant="subtle"
              icon="ArrowLeft"
              onClick={() => slider?.current?.prev()}
            />
            <ActionButton
              size="sm"
              variant="subtle"
              icon="ArrowRight"
              onClick={() => slider?.current?.next()}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
