import { FC, ReactNode } from 'react';

import './Avatar.scss';

export type AvatarProps = {
  imageUrl?: string;
  initials?: string;
  content?: ReactNode;
};

export const Avatar: FC<AvatarProps> = ({ imageUrl, initials, content }) => {
  return (
    <div className="avatar">
      {content ? <div className="avatar__content">{content}</div> : null}
      {imageUrl ? (
        <img className="avatar__image" src={imageUrl} alt="" />
      ) : initials ? (
        <div className="avatar__initials">{initials}</div>
      ) : (
        <svg className="avatar__fallback" viewBox="0 0 150 150">
          <g fill="none" fillRule="evenodd">
            <path className="avatar__background" d="M0 0h150v150H0z" />
            <path
              className="avatar__foreground"
              d="M74.5 42c12.08 0 22.103 8.962 24.004 24.942 4.023.844 6.652 5.31 5.948 10.35-.603 4.325-3.467 7.741-6.885 8.631-1.131 6.904-3.012 13.922-5.599 16.616-1.48 1.297-4.63 3.055-8.005 4.492v7.851c10.705 2.64 27.123 7.67 30.699 14.343 5.082 12.717 7.338 31.791 7.338 31.791H27s2.034-10.823 4.824-22.23l.217-.878.219-.88.221-.88c.112-.44.224-.879.338-1.317l.228-.876.115-.437.231-.87c.31-1.158.626-2.302.945-3.423 3.576-6.674 19.996-11.703 30.702-14.343v-7.852c-3.376-1.437-6.525-3.194-8.006-4.491-2.566-2.186-4.502-9.436-5.653-16.68-3.317-.978-6.069-4.338-6.657-8.565-.694-4.965 1.847-9.374 5.768-10.31C52.38 50.977 62.41 42 74.5 42Z"
            />
          </g>
        </svg>
      )}
    </div>
  );
};
