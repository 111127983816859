import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import './Hint.scss';
import { getIcon, IconName } from './Icon';

type HintVariant = 'positive' | 'negative' | 'warning' | 'neutral';

const getIconForVariant = (variant: HintVariant): IconName => {
  switch (variant) {
    case 'positive':
      return 'Closed';
    case 'negative':
      return 'Alert';
    case 'warning':
      return 'Blocked';
    case 'neutral':
      return 'Compass';
  }
};

export type HintProps = {
  variant?: HintVariant;
  title: ReactNode;
  description?: ReactNode;
};

export const Hint: FC<HintProps> = ({
  variant = 'neutral',
  title,
  description,
}) => {
  const IconComponent = getIcon(getIconForVariant(variant));

  return (
    <div className={classNames('hint', `hint--${variant}`)}>
      <div className="hint__header">
        <div className="hint__icon">
          <IconComponent size={20} />
        </div>
        {title ? <div className="hint__title">{title}</div> : null}
      </div>
      {description ? (
        <div className="hint__description">{description}</div>
      ) : null}
    </div>
  );
};
