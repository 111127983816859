import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { Spacing } from '../types';

import './Stack.scss';

export type StackProps = PropsWithChildren<{
  gap?: Spacing;
  stretched?: boolean;
}>;

export const Stack: FC<StackProps> = ({ gap = 'md', stretched, children }) => {
  return (
    <div
      className={classNames('stack', `stack--${gap}`, {
        'stack--stretched': stretched,
      })}
    >
      {children}
    </div>
  );
};
