import { FC, useMemo } from 'react';
import {
  WorkflowBuilderInternalWorkflowDialog,
  WorkflowBuilderInternalWorkflowDialogElement,
} from './types';
import {
  VisualizerDivider,
  getVisualizerDividerVariantForIndex,
} from './VisualizerDivider';
import { VisualizerDialogElement } from './VisualizerDialogElement';
import { useWorkflow } from '../../../utils/context/workflow';
import { bus } from '../../../utils/bus';
import { waitFor } from '../../../utils/helpers';
import { isLinkingElement } from '../../../utils/dialogs';

import './VisualizerDialog.scss';

export type VisualizerDialogProps = {
  dialog: WorkflowBuilderInternalWorkflowDialog;
  element?: WorkflowBuilderInternalWorkflowDialogElement;
};

export const VisualizerDialog: FC<VisualizerDialogProps> = ({
  dialog,
  element,
}) => {
  const workflow = useWorkflow();
  const step = useMemo(
    () =>
      workflow.steps.find((step) =>
        step.parties.some((partyItem) =>
          partyItem.dialogs.some((dialogItem) => dialogItem.id === dialog.id),
        ),
      ),
    [dialog.id, workflow.steps],
  );
  const party = useMemo(
    () =>
      workflow.steps
        .find((step) =>
          step.parties.some((partyItem) =>
            partyItem.dialogs.some((dialogItem) => dialogItem.id === dialog.id),
          ),
        )
        ?.parties.find((partyItem) =>
          partyItem.dialogs.some((dialogItem) => dialogItem.id === dialog.id),
        ),
    [dialog.id, workflow.steps],
  );

  return (
    <div
      className="visualizer-dialog"
      title={`${step?.name} / ${party?.name} / ${dialog.name}`}
    >
      <button
        className="visualizer-dialog__shape"
        onClick={async () => {
          bus.emit('workflow-builder:step', {
            stepId: step?.id,
          });
          await waitFor(50);
          bus.emit('workflow-builder:party', {
            partyId: party?.id,
          });
          await waitFor(50);
          bus.emit('workflow-builder:dialog', {
            dialogId: dialog.id,
          });
        }}
      >
        {dialog.name}
      </button>
      <div className="visualizer-dialog__elements">
        {dialog.elements
          .filter((elementItem) => !element || elementItem.id === element.id)
          .filter((elementItem) => isLinkingElement(elementItem?.type))
          .map((element, index, array) => (
            <div key={element.id} className="visualizer-dialog__element">
              <VisualizerDivider
                variant={getVisualizerDividerVariantForIndex(index, array)}
              />
              <VisualizerDialogElement element={element} />
            </div>
          ))}
      </div>
    </div>
  );
};
