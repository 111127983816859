import { FC } from 'react';
import {
  Navigation as NavigationComponent,
  NavigationItem,
} from '../components/Navigation';
import { useRights } from '../utils/rights';

export const Navigation: FC = () => {
  const { isAllowed } = useRights();
  const items: NavigationItem[] = [
    {
      id: 'dashboard',
      to: '/',
      icon: 'Dashboard',
      label: 'Dashboard',
    },
    {
      id: 'project',
      to: '/project',
      icon: 'Compass',
      label: 'Projektübersicht',
    },
    {
      id: 'documents',
      to: '/documents',
      icon: 'DocumentFilled',
      label: 'Dokumente',
    },
    {
      id: 'messaging',
      to: '/messaging',
      icon: 'Chat',
      label: 'Korrespondenz',
    },
    {
      id: 'protocols',
      to: '/protocols',
      icon: 'Edit',
      label: 'Protokolle',
    },
    {
      id: 'reports',
      to: '/reports',
      icon: 'Clipboard',
      label: 'Bautagesberichte',
    },
    {
      id: 'tracking',
      to: '/tracking',
      icon: 'Timeline',
      label: 'Echtzeit Bauablauf',
    },
    {
      id: 'support',
      to: '/support',
      icon: 'Support',
      label: 'Hilfe & Erklärung',
      indicator: 5,
    },
    {
      id: 'admin',
      to: '/admin',
      icon: 'Shield',
      label: 'Administration',
    },
  ];

  return (
    <NavigationComponent items={items.filter((item) => isAllowed(item.id))} />
  );
};
