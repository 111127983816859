import { FC, PropsWithChildren, createContext, useContext } from 'react';
import classNames from 'classnames';
import { useStickyState } from '../state';

const prefersDarkMode = window.matchMedia(
  '(prefers-color-scheme: dark)',
).matches;

export type Theme = 'light' | 'dark';

export type ThemeApi = {
  theme: Theme;
  setTheme: (newTheme: Theme) => void;
};

export const ThemeContext = createContext<ThemeApi>({
  theme: 'dark',
  setTheme: () => undefined,
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useStickyState<Theme>(
    prefersDarkMode ? 'dark' : 'light',
    'theme',
  );

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={classNames('theme', `theme--${theme}`)}>{children}</div>
    </ThemeContext.Provider>
  );
};
