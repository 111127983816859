import { createContext, useContext } from 'react';

export type SkeletonApi = {
  collapsed: boolean;
};

export const SkeletonContext = createContext<SkeletonApi>({
  collapsed: false,
});

export const useSkeleton = () => useContext(SkeletonContext);
