import { FC, useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  WorkflowBuilderInternalWorkflow,
  WorkflowBuilderInternalWorkflowParty,
  WorkflowBuilderInternalWorkflowStep,
} from './types';
import { Stack } from '../../../components/Stack';
import { FormField } from '../../../components/FormField';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { ActionButton } from '../../../components/ActionButton';
import { Drawer } from '../../../components/Drawer';
import { PartyEditor } from './PartyEditor';
import { Tile } from '../../../components/Tile';
import { Cluster } from '../../../components/Cluster';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../../components/StrictModeDroppable';
import { useDraggableInPortal } from '../../../utils/dnd';
import { bus } from '../../../utils/bus';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

export type StepEditorProps = {
  item?: WorkflowBuilderInternalWorkflowStep;
  workflow: WorkflowBuilderInternalWorkflow;
  onSave: (newItem: WorkflowBuilderInternalWorkflowStep) => void;
  onClose: () => void;
};

export const StepEditor: FC<StepEditorProps> = ({ item, onSave, onClose }) => {
  const renderDraggable = useDraggableInPortal();
  const [name, setName] = useState<string>(item?.name ?? '');
  const [parties, setParties] = useState<
    WorkflowBuilderInternalWorkflowParty[]
  >(item?.parties ?? []);
  const [showAddPartyDrawer, setShowAddPartyDrawer] = useState<boolean>(false);
  const [currentPartyEditorItemId, setCurrentPartyEditorItemId] = useState<
    string | undefined
  >(undefined);
  const currentPartyEditorItem = useMemo(
    () => parties.find((party) => party.id === currentPartyEditorItemId),
    [currentPartyEditorItemId, parties],
  );

  const addParty = (newParty: WorkflowBuilderInternalWorkflowParty) => {
    setParties((currentParties) => [...currentParties, newParty]);
  };

  const updateParty = (updatedParty: WorkflowBuilderInternalWorkflowParty) => {
    setParties((currentParties) =>
      currentParties.map((party) =>
        party.id === updatedParty.id ? updatedParty : party,
      ),
    );
  };

  const deleteParty = (deletedStep: WorkflowBuilderInternalWorkflowParty) => {
    setParties((currentParties) =>
      currentParties.filter((party) => party.id !== deletedStep.id),
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const sortingResult = [...parties];
    const [removed] = sortingResult.splice(source.index, 1);
    sortingResult.splice(destination.index, 0, removed);
    setParties(sortingResult);
  };

  useEffect(() => {
    bus.on('workflow-builder:party', ({ partyId }) => {
      setCurrentPartyEditorItemId(partyId);
    });
  }, []);

  useDeepCompareEffectNoCheck(() => {
    onSave({
      id: item?.id ?? nanoid(),
      name,
      parties,
    });
  }, [item, name, parties]);

  return (
    <>
      <form>
        <Stack>
          <FormField id="step_name" label="Interner Name">
            <Input value={name} onChange={(newName) => setName(newName)} />
          </FormField>
          <Tile variant="plain">
            <Stack>
              {parties.length ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <StrictModeDroppable droppableId="parties">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Stack>
                          {parties.map((party, index) => (
                            <Draggable
                              key={party.id}
                              draggableId={party.id}
                              index={index}
                            >
                              {renderDraggable((provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    userSelect: 'none',
                                  }}
                                >
                                  <Card
                                    key={party.id}
                                    title={party.name}
                                    actions={
                                      <Cluster>
                                        <ActionButton
                                          variant="ghost"
                                          size="sm"
                                          icon="Cross"
                                          onClick={() => deleteParty(party)}
                                        />
                                        <ActionButton
                                          variant="ghost"
                                          size="sm"
                                          icon="Edit"
                                          onClick={() =>
                                            setCurrentPartyEditorItemId(
                                              party.id,
                                            )
                                          }
                                        />
                                      </Cluster>
                                    }
                                  >
                                    {party.dialogs.length} Dialog
                                    {party.dialogs.length === 1 ? '' : 'e'}
                                  </Card>
                                </div>
                              ))}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </Stack>
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              ) : null}
              <Button
                label="Partei hinzufügen"
                onClick={() => setShowAddPartyDrawer(true)}
              />
            </Stack>
          </Tile>
        </Stack>
      </form>
      {currentPartyEditorItem ? (
        <Drawer
          key={currentPartyEditorItem.id}
          title="Partei bearbeiten"
          onClose={() => setCurrentPartyEditorItemId(undefined)}
          onCloseAll={() => {
            setCurrentPartyEditorItemId(undefined);
            onClose();
          }}
        >
          <PartyEditor
            item={currentPartyEditorItem}
            step={item}
            onSave={(updatedParty) => {
              updateParty(updatedParty);
            }}
            onClose={() => onClose()}
          />
        </Drawer>
      ) : null}
      {showAddPartyDrawer ? (
        <Drawer
          key="add-party"
          title="Partei hinzufügen"
          onClose={() => setShowAddPartyDrawer(false)}
          onCloseAll={() => {
            setShowAddPartyDrawer(false);
            onClose();
          }}
        >
          <PartyEditor
            step={item}
            onSave={(newParty) => {
              const id = nanoid();
              addParty({ ...newParty, id });
              setShowAddPartyDrawer(false);
              setCurrentPartyEditorItemId(id);
            }}
            onClose={() => onClose()}
          />
        </Drawer>
      ) : null}
    </>
  );
};
