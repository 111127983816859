import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type TimelineApi = {
  expandedItems: string[];
  setExpandedItems: Dispatch<SetStateAction<string[]>>;
  minDate: Date;
  maxDate: Date;
  now: Date;
  range: number;
};

export const TimelineContext = createContext<TimelineApi>({
  expandedItems: [],
  setExpandedItems: () => undefined,
  minDate: new Date(),
  maxDate: new Date(),
  now: new Date(),
  range: 0,
});

export const useTimeline = (): TimelineApi => useContext(TimelineContext);
