const getNamespacedKey = (key: string): string => ['nodicon', key].join(':');

export const getItem = <T>(key: string): T | undefined => {
  try {
    return (JSON.parse(
      window.localStorage.getItem(getNamespacedKey(key)) ?? 'null',
    ) ?? undefined) as T | undefined;
  } catch {
    return undefined;
  }
};

export const setItem = (key: string, value: any): void => {
  try {
    return window.localStorage.setItem(
      getNamespacedKey(key),
      JSON.stringify(value),
    );
  } catch {
    /* NOOP */
  }
};

export const removeItem = (key: string): void => {
  try {
    window.localStorage.removeItem(getNamespacedKey(key));
  } catch {
    /* NOOP */
  }
};

export const getSessionItem = <T>(key: string): T | undefined => {
  try {
    return (JSON.parse(
      window.sessionStorage.getItem(getNamespacedKey(key)) ?? 'null',
    ) ?? undefined) as T | undefined;
  } catch {
    return undefined;
  }
};

export const setSessionItem = (key: string, value: any): void => {
  try {
    return window.sessionStorage.setItem(
      getNamespacedKey(key),
      JSON.stringify(value),
    );
  } catch {
    /* NOOP */
  }
};

export const removeSessionItem = (key: string): void => {
  try {
    window.sessionStorage.removeItem(getNamespacedKey(key));
  } catch {
    /* NOOP */
  }
};
