import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Spinner } from './Spinner';
import { getIcon, IconName } from './Icon';
import { useKeyPress } from '../utils/hooks';

import './Button.scss';

export type ButtonProps = {
  variant?: 'ghost' | 'prominent' | 'subtle' | 'destructive';
  size?: 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit';
  icon?: IconName;
  label?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  stretched?: boolean;
  shortcut?: string;
  onClick: () => void;
};

export const Button: FC<ButtonProps> = ({
  variant = 'prominent',
  size = 'md',
  type = 'button',
  icon,
  label,
  disabled,
  loading,
  stretched,
  shortcut,
  onClick,
}) => {
  useKeyPress(shortcut, onClick);

  const IconComponent = icon && getIcon(icon);

  return (
    <button
      type={type}
      className={classNames('button', `button--${size}`, `button--${variant}`, {
        'button--disabled': disabled,
        'button--stretched': stretched,
        'button--loading': loading,
      })}
      onClick={(e) => {
        if (!disabled && !loading) {
          onClick();
        }
      }}
      disabled={disabled || loading}
    >
      {shortcut ? (
        <span className="button__key">
          {shortcut === 'Enter' ? '⏎' : shortcut.toUpperCase()}
        </span>
      ) : null}
      {loading ? (
        <span className="button__icon">
          <Spinner />
        </span>
      ) : null}
      {IconComponent ? (
        <span className="button__icon">
          <IconComponent size={16} />
        </span>
      ) : null}
      {label ? <span className="button__label">{label}</span> : null}
    </button>
  );
};
