import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { State, StateVariant } from './State';

import './Card.scss';

export type CardProps = PropsWithChildren<{
  state?: StateVariant;
  title: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
}>;

export const Card: FC<CardProps> = ({
  state,
  title,
  description,
  actions,
  children,
}) => {
  return (
    <div
      className={classNames('card', {
        'card--state': state,
      })}
    >
      {state ? (
        <div className="card__state">
          <State variant={state} />
        </div>
      ) : null}
      <div className="card__content">
        <div className="card__header">
          <div className="card__intro">
            <div className="card__title">{title}</div>
            {description ? (
              <div className="card__description">{description}</div>
            ) : null}
          </div>
          <div className="card__actions">{actions}</div>
        </div>
        {children}
      </div>
    </div>
  );
};
