import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '../components/Skeleton';
import { Headline } from '../components/Headline';
import { Navigation } from '../fragments/Navigation';
import { Cockpit } from '../fragments/Cockpit';
import { Stack } from '../components/Stack';
import { Copy } from '../components/Copy';
import { useAuth } from '../utils/auth';
import { getGreeting } from '../utils/ui';
import { Tiles } from '../components/Tiles';
import { Tile } from '../components/Tile';
import { ActionButton } from '../components/ActionButton';
import { QuickLinks } from '../components/QuickLinks';
import { Card } from '../components/Card';
import { Cluster } from '../components/Cluster';
import { Avatars } from '../components/Avatars';
import { Avatar } from '../components/Avatar';
import { Spacer } from '../components/Spacer';
import { Badge } from '../components/Badge';

import paul from '../assets/img/paul.jpg';
import michael from '../assets/img/michael.jpg';
import ralf from '../assets/img/ralf.jpg';

export const Dashboard: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <Skeleton
      intro={
        <Stack gap="2xl">
          <Stack>
            <Headline>
              {getGreeting()}, {auth.user?.firstName}
            </Headline>
            <Copy>Es gibt neue Meldungen zu deinem Projekt</Copy>
          </Stack>
          <Tiles
            items={
              <>
                <Tile
                  variant="blue"
                  title="Aufgaben"
                  value="21"
                  description="Neue Dokumente und Aktualisierungen"
                  actions={
                    <ActionButton
                      variant="subtle"
                      size="sm"
                      icon="ChevronRight"
                      onClick={() => navigate('/project')}
                    />
                  }
                />
                <Tile
                  variant="pink"
                  title="Dokumente"
                  value="12"
                  description="+12%"
                  actions={
                    <ActionButton
                      variant="subtle"
                      size="sm"
                      icon="ChevronRight"
                      onClick={() => navigate('/documents')}
                    />
                  }
                />
              </>
            }
            highlight={
              <Tile
                variant="orange"
                title="Einflüsse auf den Echtzeit-Bauablauf"
                value="5"
                description="+12% offene Aufgaben"
                actions={
                  <ActionButton
                    variant="subtle"
                    size="sm"
                    icon="ChevronRight"
                    onClick={() => navigate('/tracking')}
                  />
                }
              />
            }
            sidebar={
              <Tile
                variant="neutral"
                title="Deine Shortcuts"
                actions={
                  <ActionButton
                    variant="subtle"
                    size="sm"
                    icon="Edit"
                    onClick={() => undefined}
                  />
                }
              >
                <QuickLinks
                  items={[
                    {
                      id: 'messaging',
                      icon: 'Plus',
                      label: 'Korrespondenz',
                      onClick: () => navigate('/messaging'),
                    },
                    {
                      id: 'documents',
                      icon: 'Plus',
                      label: 'Dokument einreichen',
                      onClick: () => navigate('/documents'),
                    },
                    {
                      id: 'reports',
                      icon: 'Plus',
                      label: 'Bautagesbericht',
                      onClick: () => navigate('/reports'),
                    },
                    {
                      id: 'protocols',
                      icon: 'Plus',
                      label: 'Protokoll',
                      onClick: () => navigate('/protocols'),
                    },
                  ]}
                />
              </Tile>
            }
          />
        </Stack>
      }
      navigation={<Navigation />}
      cockpit={<Cockpit />}
      main={
        <Tile
          variant="plain"
          title="Top Aufgaben des Tages"
          actions={
            <ActionButton
              variant="subtle"
              size="sm"
              icon="Edit"
              onClick={() => navigate('/project')}
            />
          }
        >
          <Stack>
            {(
              [
                {
                  state: 'open',
                  title:
                    'Gerätschaften blockieren den Eingangsbereich zur Treppe',
                  avatars: [paul, michael, ralf],
                  badges: (
                    <Cluster>
                      <Badge variant="primary" label="Bedenken" />
                    </Cluster>
                  ),
                },
                {
                  state: 'closed',
                  title:
                    'Gerätschaften blockieren den Eingangsbereich zur Treppe',
                  avatars: [paul],
                  badges: (
                    <Cluster>
                      <Badge variant="positive" label="Sachnachtrag" />
                      <Badge variant="negative" label="Blockiert" />
                    </Cluster>
                  ),
                },
                {
                  state: 'blocked',
                  title:
                    'Gerätschaften blockieren den Eingangsbereich zur Treppe',
                  avatars: [ralf, paul],
                  badges: (
                    <Cluster>
                      <Badge label="Bedenken" />
                    </Cluster>
                  ),
                },
                {
                  state: 'alert',
                  title:
                    'Gerätschaften blockieren den Eingangsbereich zur Treppe',
                  avatars: [michael, ralf],
                  badges: (
                    <Cluster>
                      <Badge label="Bedenken" />
                    </Cluster>
                  ),
                },
                {
                  state: 'neutral',
                  title:
                    'Gerätschaften blockieren den Eingangsbereich zur Treppe',
                  avatars: [ralf],
                  badges: (
                    <Cluster>
                      <Badge label="Bedenken" />
                      <Badge variant="negative" label="Wichtig" />
                    </Cluster>
                  ),
                },
              ] as const
            ).map(({ title, state, avatars, badges }, index) => (
              <Card
                key={index}
                state={state}
                title={title}
                description={`#${index + 1} geöffnet vor ${
                  index + 1
                } Stunden von Paul Popescu // Bauteil A, 2. OG Zimmer 7`}
                actions={
                  <Cluster>
                    <ActionButton
                      variant="ghost"
                      size="sm"
                      icon="Notification"
                      onClick={() => undefined}
                    />
                    <ActionButton
                      variant="ghost"
                      size="sm"
                      icon="More"
                      onClick={() => undefined}
                    />
                  </Cluster>
                }
              >
                <Cluster stretched>
                  <Avatars>
                    {avatars.map((url) => (
                      <Avatar key={url} imageUrl={url} />
                    ))}
                  </Avatars>
                  <Spacer />
                  {badges}
                </Cluster>
              </Card>
            ))}
          </Stack>
        </Tile>
      }
    />
  );
};
