import { FC, ReactNode } from 'react';
import { WorkflowBuilderInternalWorkflowDialogElement } from './types';
import { ArrowUp, ChevronLeft, ChevronRight } from '../../../components/Icon';

import './DialogElementPreview.scss';
import { format } from 'date-fns';
import { Avatar } from '../../../components/Avatar';
import classNames from 'classnames';

export type DialogElementPreviewProps = {
  element: WorkflowBuilderInternalWorkflowDialogElement;
};

export const DialogElementPreview: FC<DialogElementPreviewProps> = ({
  element,
}) => {
  const renderType = (): ReactNode => {
    switch (element.type) {
      case 'DialogButton':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--button">
            {element.text || 'Button'}
          </div>
        );
      case 'DialogBlätternWeiter':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--pagination">
            <ChevronRight size={12} />
          </div>
        );
      case 'DialogBlätternZurück':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--pagination">
            <ChevronLeft size={12} />
          </div>
        );
      case 'DialogButtonSchließen':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--close">
            {element.text || 'Schließen'}
          </div>
        );
      case 'DialogButtonSenden':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--send">
            {element.text || 'Senden'}
          </div>
        );
      case 'DialogTextField':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--text-field">
            {element.text || 'Text Field'}
          </div>
        );
      case 'DialogHeadline':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--headline">
            {element.text || 'Headline'}
          </div>
        );
      case 'DialogSubline':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--subline">
            {element.text || 'Subline'}
          </div>
        );
      case 'DialogCommentBox':
        const comments = (element.comment ?? [])
          .map((line) => line.trim())
          .filter(Boolean);

        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--comment-box">
            <div className="dialog-element-preview__headline">
              {element.text || 'Comment Box'}
            </div>
            <div className="dialog-element-preview__content">
              {comments[0] || 'Some comment'}
              <div className="dialog-element-preview_author">
                <Avatar />
                {element.authorName || 'John Doe'}
              </div>
            </div>
            <div className="dialog-element-preview__pagination">
              {Array.from({ length: comments.length || 3 }).map((_, index) => (
                <div
                  key={index}
                  className={classNames('dialog-element-preview__page', {
                    'dialog-element-preview__page--current': index === 0,
                  })}
                />
              ))}
            </div>
          </div>
        );
      case 'DialogFileUpload':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--file-upload">
            <ArrowUp size={16} />
            {element.text || 'File Upload'}
          </div>
        );
      case 'DialogChoice':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--choice">
            <div className="dialog-element-preview__key">
              {(element.text || 'Choice').substr(0, 1)}
            </div>
            {element.text || 'Choice'}
          </div>
        );
      case 'DialogDatePicker':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--date-picker">
            {format(new Date(), 'dd.LL.yyyy, HH:mm:ss')}
          </div>
        );
      case 'DialogPersonPicker':
        return (
          <div className="dialog-element-preview__element dialog-element-preview__element--person-picker">
            <div className="dialog-element-preview__input">
              {element.text || 'Person Picker'}
            </div>
            <div className="dialog-element-preview__persons">
              <div className="dialog-element-preview__person">
                <Avatar />
                John Doe
              </div>
              <div className="dialog-element-preview__person">
                <Avatar />
                Jane Doe
              </div>
              <div className="dialog-element-preview__person">
                <Avatar />
                Jim Bob
              </div>
            </div>
          </div>
        );
    }
  };

  return <div className="dialog-element-preview">{renderType()}</div>;
};
