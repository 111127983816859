import { get } from '../request';
import { ApiResponse, WithIds, isErrorResponse } from './common';

export type ApiNotification = WithIds<{
  benutzerAId: string;
  benutzerBId: string;
  tokenId: string;
  dialogButtonId: string;
  status: string;
  quelle: string;
}>;

export type ApiNotificationItem = WithIds<{
  workflowId: string;
  beschreibung: string;
  kurzBezeichnung: string;
  vertragsbeziehung?: string;
  partei?: string;
  currentDialogId?: string;
  eintraege: {
    rolle: string;
    content: never[];
    dialogId: string;
    name: string;
  }[];
}>;

export type NotificationResponse = {
  Verfasser_BenutzerA: ApiNotificationItem[];
  Addressat_BenutzerB: ApiNotificationItem[];
};

export type Notification = {
  id: string;
  label: string;
  description?: string;
};

export type GetNotificationsProps = {
  userId: string;
};

export const getNotifications = async (
  props: GetNotificationsProps,
): Promise<Notification[]> => {
  const result = await get<ApiResponse<NotificationResponse>>(
    '/dialog/getTokenFürBenutzerId',
    {
      searchParams: new URLSearchParams({
        benutzerId: props.userId,
      }),
    },
  );

  if (isErrorResponse(result)) {
    return [];
  }

  return [result.Verfasser_BenutzerA, result.Addressat_BenutzerB]
    .flat()
    .map((item) => ({
      id: item._idAsString,
      label: item.kurzBezeichnung,
      description: item.beschreibung,
    }));
};
