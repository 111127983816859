import { useAuth } from './auth';

export type UseRightsApi = {
  isAllowed: (right: string) => boolean;
};

export const useRights = (): UseRightsApi => {
  const auth = useAuth();
  return {
    isAllowed: (right: string) =>
      auth.rights.includes(right) ||
      ((right === 'admin' || right.startsWith('admin/')) &&
        auth.user?.role === 'ADMINISTRATOR') ||
      auth.user?.role === 'USER',
  };
};
