import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Divider } from './Divider';
import { ChevronLeft, ChevronRight } from './Icon';
import { Logo } from './Logo';
import { SkeletonContext, useTheme } from '../utils/context';
import { useStickyState } from '../utils/state';
import { ThemeSwitcher } from './ThemeSwitcher';

import './Skeleton.scss';

export type SkeletonProps = {
  navigation?: ReactNode;
  cockpit?: ReactNode;
  intro?: ReactNode;
  main: ReactNode;
  colliding?: boolean;
};

export const Skeleton: FC<SkeletonProps> = ({
  navigation,
  cockpit,
  intro,
  main,
  colliding,
}) => {
  const [collapsed, setCollapsed] = useStickyState<boolean>(
    false,
    'skeleton:collapsed',
  );
  const { theme, setTheme } = useTheme();

  return (
    <SkeletonContext.Provider
      value={{
        collapsed,
      }}
    >
      <div
        className={classNames('skeleton', {
          'skeleton--navigation': navigation,
          'skeleton--cockpit': cockpit,
          'skeleton--intro': intro,
          'skeleton--main': main,
          'skeleton--colliding': colliding,
          'skeleton--collapsed': collapsed,
        })}
      >
        <div className="skeleton__backdrop" />
        <div className="skeleton__sidebar">
          <button
            className="skeleton__toggle"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
          </button>
          <div className="skeleton__drawer">
            <div className="skeleton__header">
              <Logo collapsed={collapsed} />
            </div>
            {navigation ? (
              <div className="skeleton__divider">
                <Divider />
              </div>
            ) : null}
            {navigation ? (
              <div className="skeleton__navigation">{navigation}</div>
            ) : null}
            <div className="skeleton__footer">
              <div className="skeleton__divider">
                <Divider />
              </div>
              <div className="skeleton__theme">
                <ThemeSwitcher
                  theme={theme}
                  onChange={(newTheme) => setTheme(newTheme)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="skeleton__content">
          <div className="skeleton__details">
            <div className="skeleton__cockpit">{cockpit}</div>
            <div className="skeleton__divider">
              <Divider />
            </div>
            {intro ? <div className="skeleton__intro">{intro}</div> : null}
          </div>
          <div className="skeleton__main">{main}</div>
        </div>
      </div>
    </SkeletonContext.Provider>
  );
};
