import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { useAuth } from '../utils/auth';
import { Avatar } from '../components/Avatar';
import { SearchInput } from '../components/SearchInput';
import { ActionButton } from '../components/ActionButton';
import { Cluster } from '../components/Cluster';
import { Divider } from '../components/Divider';
import { Flyout } from '../components/Flyout';
import { Notification, getNotifications } from '../services/api/notifications';
import { Stack } from '../components/Stack';
import { Card } from '../components/Card';

import './Cockpit.scss';

export const Cockpit: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const updateNotifications = async () => {
    if (auth.user) {
      setNotifications(
        await getNotifications({
          userId: auth.user.userId,
        }),
      );
    }
  };

  useInterval(updateNotifications, auth.user ? 5 * 1000 : null);

  useEffect(() => {
    updateNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="cockpit">
      <div className="cockpit__search">
        <SearchInput placeholder="Suche" />
      </div>
      <div className="cockpit__actions">
        <Cluster gap="xl">
          <Flyout
            open={showNotifications}
            title="Benachrichtigungen"
            trigger={
              <ActionButton
                variant="subtle"
                icon="Notification"
                onClick={() => setShowNotifications(true)}
                indicator={notifications.length}
              />
            }
            onClose={() => setShowNotifications(false)}
          >
            <Stack>
              {notifications.map((notification) => (
                <Card
                  key={notification.id}
                  title={notification.label}
                  description={notification.description}
                />
              ))}
            </Stack>
          </Flyout>
          <ActionButton
            variant="subtle"
            icon="Message"
            onClick={() => navigate('/messaging')}
          />
          {auth.user ? (
            <ActionButton
              variant="subtle"
              icon="Logout"
              onClick={() => auth.handleLogout()}
            />
          ) : null}
        </Cluster>
      </div>
      <div className="cockpit__divider">
        <Divider vertical />
      </div>
      {auth.user ? (
        <div className="cockpit__avatar">
          <Avatar
            content={
              <>
                Hallo, <strong>{auth.user.firstName}</strong>
              </>
            }
          />
        </div>
      ) : null}
    </div>
  );
};
