export const getGreeting = (): string => {
  const hours = new Date().getHours();
  if (hours < 12) {
    return 'Guten Morgen';
  }
  if (hours < 18) {
    return 'Guten Tag';
  }
  return 'Guten Abend';
};

export const getInitialsForName = (name: string): string => {
  return (
    name
      .split(/[\s|-]+/g)
      .map((partial) => (partial ?? '').substring(0, 1))
      .filter(Boolean)
      .slice(0, 2)
      .join('') || '?'
  ).toUpperCase();
};

export const FIRST_DAY_OF_WEEK = 1;

export const MONTH_NAMES = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezemeber',
];

export const WEEKDAY_NAMES = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
