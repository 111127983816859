import { Bus } from './bus';

export const COMMENT_DIVIDER = '\n\n';

export const COMMENT_ALIGNMENTS = {
  LEFT: 'Links',
  RIGHT: 'Rechts',
} as const;

export const internalBus = new Bus('nodicon-internal');
