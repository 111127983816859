import { addDays, format, subDays } from 'date-fns';
import { CalendarData, CalendarDataItem } from '../components/Calendar';
import { IconName } from '../components/Icon';

const WEATHER_ICONS: IconName[] = [
  'CloudSnow',
  'CloudRain',
  'CloudLightning',
  'CloudDrizzle',
  'Cloud',
  'Sun',
];

const getRandomCalendarDataItems = (): CalendarDataItem[] => {
  const items: CalendarDataItem[] = [];
  const count = Math.floor(Math.random() * 3);
  for (let i = 0; i < count; i++) {
    items.push({
      id: `item-${i + 1}`,
      label: `Nr. ${i + 1} Lorem ipsum dolor sit amet`,
    });
  }
  return items;
};

export const getRandomCalendarDataWeather = (): IconName =>
  WEATHER_ICONS[Math.floor(Math.random() * WEATHER_ICONS.length)];

export const getCalendarMockData = (): Record<
  string,
  CalendarData | undefined
> => {
  const now = new Date();
  const cache = [now];
  for (let i = 0; i < 60; i++) {
    cache.unshift(subDays(now, i));
    cache.push(addDays(now, i));
  }

  return cache.reduce((acc, date) => {
    return {
      ...acc,
      [format(date, 'yyyy-MM-dd')]: {
        items: getRandomCalendarDataItems(),
        weather: getRandomCalendarDataWeather(),
        events: Math.random() > 0.5,
      },
    };
  }, {} as Record<string, CalendarData | undefined>);
};
