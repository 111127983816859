import { FC } from 'react';
import { Search } from './Icon';

import './SearchInput.scss';

export type SearchInputProps = {
  value?: string | number;
  placeholder?: string;
  onChange?: (newValue: string) => void;
};

export const SearchInput: FC<SearchInputProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  return (
    <div className="search-input">
      <span className="search-input__icon">
        <Search />
      </span>
      <input
        className="search-input__element"
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </div>
  );
};
