// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Handler<T = any> = (data?: T) => void;

export type WrappedHandler = (e: Event) => void;

export class Bus {
  private prefix: string;
  private handlers: Record<string, Map<Handler, WrappedHandler>> = {};

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  emit(topic: string, data?: Record<string, unknown>): void {
    document.dispatchEvent(
      new CustomEvent(`${this.prefix}:${topic}`, {
        detail: data,
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on<T = any>(topic: string, fn: Handler<T>): void {
    const handler: WrappedHandler = (e: Event) => {
      const event = e as CustomEvent;
      fn(event.detail);
    };
    this.handlers[topic] =
      this.handlers[topic] || new Map<Handler, () => void>();
    this.handlers[topic].set(fn, handler);
    document.addEventListener(`${this.prefix}:${topic}`, handler, false);
  }

  off(topic: string, fn: Handler): void {
    const handler = this.handlers[topic].get(fn);
    if (handler) {
      document.removeEventListener(`${this.prefix}:${topic}`, handler, false);
    }
  }
}

export const bus = new Bus('nodicon');
