import { FC, PropsWithChildren, ReactNode } from 'react';
import { ActionButton } from './ActionButton';

import './Drawer.scss';

export type DrawerProps = PropsWithChildren<{
  title?: ReactNode;
  onClose: () => void;
  onCloseAll?: () => void;
}>;

export const Drawer: FC<DrawerProps> = ({
  title,
  onClose,
  onCloseAll,
  children,
}) => {
  return (
    <div className="drawer">
      <div className="drawer__backdrop" onClick={() => onClose()} />
      <div className="drawer__panel">
        <div className="drawer__header">
          <div className="drawer__title">{title || null}</div>
          <div className="drawer__actions">
            {onCloseAll ? (
              <ActionButton
                variant="subtle"
                icon="Minimize"
                onClick={() => onCloseAll()}
              />
            ) : null}
            <ActionButton
              variant="subtle"
              icon="Cross"
              onClick={() => onClose()}
            />
          </div>
        </div>
        <div className="drawer__content">{children}</div>
      </div>
    </div>
  );
};
