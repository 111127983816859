import { Children, FC, PropsWithChildren } from 'react';

import './Avatars.scss';

export const Avatars: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="avatars">
      {Children.map(children, (child) =>
        child ? <div className="avatars__item">{child}</div> : null,
      )}
    </div>
  );
};
