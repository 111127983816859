import { FC, useState } from 'react';
import { Skeleton } from '../components/Skeleton';
import { Headline } from '../components/Headline';
import { Navigation } from '../fragments/Navigation';
import { Cockpit } from '../fragments/Cockpit';
import { Stack } from '../components/Stack';
import { Copy } from '../components/Copy';
import { Tabs } from '../components/Tabs';
import { Filters } from '../components/Filters';
import { Tile } from '../components/Tile';
import { ActionButton } from '../components/ActionButton';
import { Cluster } from '../components/Cluster';
import { Badge } from '../components/Badge';
import { Card } from '../components/Card';
import { Avatars } from '../components/Avatars';
import { Avatar } from '../components/Avatar';
import { Spacer } from '../components/Spacer';
import { Legend } from '../components/Legend';
import { Button } from '../components/Button';
import { MessageWizard } from '../fragments/messages/MessageWizard';

import paul from '../assets/img/paul.jpg';
import michael from '../assets/img/michael.jpg';
import ralf from '../assets/img/ralf.jpg';

export const Messaging: FC = () => {
  const [currentFilter, setCurrentFilter] = useState<string>('all');
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  return (
    <>
      <Skeleton
        intro={
          <Stack gap="2xl">
            <Cluster y="end">
              <Stack>
                <Headline>Korrespondenz</Headline>
                <Copy>Es gibt neue Meldungen zu deinem Projekt</Copy>
              </Stack>
              <Spacer />
              <Button
                icon="PlusCircle"
                label="Korrespondenz"
                onClick={() => setShowAddModal(true)}
              />
            </Cluster>
            <Tabs
              items={[
                {
                  id: 'my',
                  to: '/messaging',
                  label: 'Deine Aufgaben',
                },
                {
                  id: 'open',
                  to: '/messaging/open',
                  label: 'Offen',
                },
                {
                  id: 'blocked',
                  to: '/messaging/blocked',
                  label: 'Blockiert',
                },
                {
                  id: 'closed',
                  to: '/messaging/closed',
                  label: 'Geschlossen',
                },
                {
                  id: 'all',
                  to: '/messaging/all',
                  label: 'Alle',
                },
              ]}
            />
          </Stack>
        }
        navigation={<Navigation />}
        cockpit={<Cockpit />}
        main={
          <Filters
            items={[
              {
                id: 'all',
                label: 'Alle',
                indicator: 19,
                onClick: () => setCurrentFilter('all'),
                active: currentFilter === 'all',
              },
              {
                id: 'a',
                label: 'Bedenken',
                indicator: 9,
                onClick: () => setCurrentFilter('a'),
                active: currentFilter === 'a',
              },
              {
                id: 'b',
                label: 'Behinderung',
                indicator: 4,
                onClick: () => setCurrentFilter('b'),
                active: currentFilter === 'b',
              },
              {
                id: 'c',
                label: 'Sachnachtrag',
                indicator: 2,
                onClick: () => setCurrentFilter('c'),
                active: currentFilter === 'c',
              },
              {
                id: 'd',
                label: 'Mitwirkung',
                indicator: 2,
                onClick: () => setCurrentFilter('d'),
                active: currentFilter === 'd',
              },
            ]}
          >
            <Tile variant="plain">
              <Stack>
                {[
                  'Donnerstag, 08. Juni',
                  'Mittwoch, 07. Juni',
                  'Dienstag, 06. Juni',
                ].map((date) => (
                  <Stack key={date}>
                    <Legend>{date}</Legend>
                    <Stack>
                      {(
                        [
                          {
                            state: 'open',
                            title:
                              'Gerätschaften blockieren den Eingangsbereich zur Treppe',
                            avatars: [paul, michael, ralf],
                            badges: (
                              <Cluster>
                                <Badge variant="primary" label="Bedenken" />
                              </Cluster>
                            ),
                          },
                          {
                            state: 'closed',
                            title:
                              'Gerätschaften blockieren den Eingangsbereich zur Treppe',
                            avatars: [paul],
                            badges: (
                              <Cluster>
                                <Badge
                                  variant="positive"
                                  label="Sachnachtrag"
                                />
                                <Badge variant="negative" label="Blockiert" />
                              </Cluster>
                            ),
                          },
                          {
                            state: 'blocked',
                            title:
                              'Gerätschaften blockieren den Eingangsbereich zur Treppe',
                            avatars: [ralf, paul],
                            badges: (
                              <Cluster>
                                <Badge label="Bedenken" />
                              </Cluster>
                            ),
                          },
                        ] as const
                      ).map(({ title, state, avatars, badges }, index) => (
                        <Card
                          key={index}
                          state={state}
                          title={title}
                          description={`#${index + 1} geöffnet vor ${
                            index + 1
                          } Stunden von Paul Popescu // Bauteil A, 2. OG Zimmer 7`}
                          actions={
                            <Cluster>
                              <ActionButton
                                variant="ghost"
                                size="sm"
                                icon="Notification"
                                onClick={() => undefined}
                              />
                              <ActionButton
                                variant="ghost"
                                size="sm"
                                icon="More"
                                onClick={() => undefined}
                              />
                            </Cluster>
                          }
                        >
                          <Cluster stretched>
                            <Avatars>
                              {avatars.map((url) => (
                                <Avatar key={url} imageUrl={url} />
                              ))}
                            </Avatars>
                            <Spacer />
                            {badges}
                          </Cluster>
                        </Card>
                      ))}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Tile>
          </Filters>
        }
      />
      {showAddModal ? (
        <MessageWizard onRequestClose={() => setShowAddModal(false)} />
      ) : null}
    </>
  );
};
