import { FC } from 'react';
import classNames from 'classnames';

import './Divider.scss';

export type DividerProps = {
  vertical?: boolean;
};

export const Divider: FC<DividerProps> = ({ vertical }) => {
  return (
    <hr
      className={classNames('divider', {
        'divider--vertical': vertical,
        'divider--horizontal': !vertical,
      })}
    />
  );
};
