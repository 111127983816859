import { FC } from 'react';
import { Skeleton } from '../components/Skeleton';
import { Headline } from '../components/Headline';
import { Navigation } from '../fragments/Navigation';
import { Cockpit } from '../fragments/Cockpit';
import { Stack } from '../components/Stack';
import { Copy } from '../components/Copy';
import { Tabs } from '../components/Tabs';
import { Tile } from '../components/Tile';
import { ActionButton } from '../components/ActionButton';
import { Legend } from '../components/Legend';

export const Project: FC = () => {
  return (
    <Skeleton
      intro={
        <Stack gap="2xl">
          <Stack>
            <Headline>Projektübersicht</Headline>
            <Copy>Alle relevanten Daten zu Ihrem Projekt</Copy>
          </Stack>
          <Tabs
            items={[
              {
                id: 'overview',
                to: '/project',
                label: 'Allgemein',
              },
              {
                id: 'organigram',
                to: '/project/organigram',
                label: 'Organigramm',
              },
              {
                id: 'stakeholders',
                to: '/project/stakeholders',
                label: 'Projektbeteiligte',
              },
            ]}
          />
        </Stack>
      }
      navigation={<Navigation />}
      cockpit={<Cockpit />}
      main={
        <Tile
          title="Mehrparteienhaus Asbergplatz, Köln"
          actions={
            <ActionButton
              variant="ghost"
              size="sm"
              icon="Edit"
              onClick={() => undefined}
            />
          }
        >
          <Stack>
            <Stack>
              <Legend>Adresse</Legend>
              <Stack gap="none">
                <Copy>Nonnenwerthstr. 103</Copy>
                <Copy>50937 Köln</Copy>
              </Stack>
            </Stack>
            <Stack>
              <Legend>Auftraggeber</Legend>
              <Stack gap="none">
                <Copy>Asberg Colonia GmbH</Copy>
                <Copy>Scherfginstr. 7</Copy>
                <Copy>50937 Köln</Copy>
              </Stack>
            </Stack>
            <Stack>
              <Legend>Vertretungsberechtigte Person</Legend>
              <Stack gap="none">
                <Copy>Helmut Schmitz</Copy>
                <Copy>Telefon: 0221/38975</Copy>
              </Stack>
            </Stack>
          </Stack>
        </Tile>
      }
    />
  );
};
