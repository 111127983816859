const locks = new Set();

export const lockBodyScrolling = (id: string): void => {
  locks.add(id);

  document.body.classList.add('scroll-lock');
};

export const unlockBodyScrolling = (id: string): void => {
  locks.delete(id);

  if (locks.size === 0) {
    document.body.classList.remove('scroll-lock');
  }
};
