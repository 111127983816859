import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Check } from './Icon';
import { useKeyPress } from '../utils/hooks';

import './Choice.scss';

export type ChoiceProps = {
  label: ReactNode;
  selected?: boolean;
  shortcut?: string;
  onClick: () => void;
};

export const Choice: FC<ChoiceProps> = ({
  label,
  shortcut,
  selected,
  onClick,
}) => {
  useKeyPress(shortcut, onClick);

  return (
    <button
      className={classNames('choice', {
        'choice--selected': selected,
      })}
      onClick={() => onClick()}
    >
      {shortcut ? (
        <span className="choice__key">{shortcut.toUpperCase()}</span>
      ) : null}
      <span className="choice__label">{label}</span>
      {selected ? (
        <div className="choice__indicator">
          <Check />
        </div>
      ) : null}
    </button>
  );
};
