import { FC, useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  WorkflowBuilderInternalWorkflowDialog,
  WorkflowBuilderInternalWorkflowDialogElement,
  WorkflowBuilderInternalWorkflowParty,
} from './types';
import { Stack } from '../../../components/Stack';
import { FormField } from '../../../components/FormField';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { ActionButton } from '../../../components/ActionButton';
import { Drawer } from '../../../components/Drawer';
import { DialogElementEditor } from './DialogElementEditor';
import { Tile } from '../../../components/Tile';
import { Cluster } from '../../../components/Cluster';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../../components/StrictModeDroppable';
import { useDraggableInPortal } from '../../../utils/dnd';
import { bus } from '../../../utils/bus';
import { Preview } from './Preview';
import { DialogPreview } from './DialogPreview';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

export type DialogEditorProps = {
  item?: WorkflowBuilderInternalWorkflowDialog;
  party?: WorkflowBuilderInternalWorkflowParty;
  onSave: (newItem: WorkflowBuilderInternalWorkflowDialog) => void;
  onClose: () => void;
};

export const DialogEditor: FC<DialogEditorProps> = ({
  item,
  onSave,
  onClose,
}) => {
  const renderDraggable = useDraggableInPortal();
  const [name, setName] = useState<string>(item?.name ?? '');
  const [elements, setElements] = useState<
    WorkflowBuilderInternalWorkflowDialogElement[]
  >(item?.elements ?? []);
  const [showAddElementDrawer, setShowAddElementDrawer] =
    useState<boolean>(false);
  const [
    currentDialogElementEditorItemId,
    setCurrentDialogElementEditorItemId,
  ] = useState<string | undefined>(undefined);
  const currentDialogElementEditorItem = useMemo(
    () =>
      elements.find(
        (element) => element.id === currentDialogElementEditorItemId,
      ),
    [currentDialogElementEditorItemId, elements],
  );

  const addElement = (
    newElement: WorkflowBuilderInternalWorkflowDialogElement,
  ) => {
    setElements((currentElements) => [...currentElements, newElement]);
  };

  const updateElement = (
    updatedElement: WorkflowBuilderInternalWorkflowDialogElement,
  ) => {
    setElements((currentElements) =>
      currentElements.map((element) =>
        element.id === updatedElement.id ? updatedElement : element,
      ),
    );
  };

  const deleteElement = (
    deletedDialog: WorkflowBuilderInternalWorkflowDialogElement,
  ) => {
    setElements((currentElements) =>
      currentElements.filter((element) => element.id !== deletedDialog.id),
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const sortingResult = [...elements];
    const [removed] = sortingResult.splice(source.index, 1);
    sortingResult.splice(destination.index, 0, removed);
    setElements(sortingResult);
  };

  useEffect(() => {
    bus.on('workflow-builder:element', ({ elementId }) => {
      setCurrentDialogElementEditorItemId(elementId);
    });
  }, []);

  useDeepCompareEffectNoCheck(() => {
    onSave({
      id: item?.id ?? nanoid(),
      name,
      elements,
    });
  }, [item, elements, name]);

  return (
    <>
      <Stack gap="2xl">
        <form>
          <Stack>
            <FormField id="dialog_name" label="Interner Name">
              <Input value={name} onChange={(newName) => setName(newName)} />
            </FormField>
            <Tile variant="plain">
              <Stack>
                {elements.length ? (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <StrictModeDroppable droppableId="elements">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Stack>
                            {elements.map((element, index) => (
                              <Draggable
                                key={element.id}
                                draggableId={element.id}
                                index={index}
                              >
                                {renderDraggable((provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: 'none',
                                    }}
                                  >
                                    <Card
                                      key={element.id}
                                      title={`${element.name} (${element.type})`}
                                      actions={
                                        <Cluster>
                                          <ActionButton
                                            variant="ghost"
                                            size="sm"
                                            icon="Cross"
                                            onClick={() =>
                                              deleteElement(element)
                                            }
                                          />
                                          <ActionButton
                                            variant="ghost"
                                            size="sm"
                                            icon="Edit"
                                            onClick={() =>
                                              setCurrentDialogElementEditorItemId(
                                                element.id,
                                              )
                                            }
                                          />
                                        </Cluster>
                                      }
                                    >
                                      {element.text
                                        ? `»${element.text}«`
                                        : null}
                                    </Card>
                                  </div>
                                ))}
                              </Draggable>
                            ))}

                            {provided.placeholder}
                          </Stack>
                        </div>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext>
                ) : null}
                <Button
                  label="Element hinzufügen"
                  onClick={() => setShowAddElementDrawer(true)}
                />
              </Stack>
            </Tile>
          </Stack>
        </form>
        <Preview variant="background">
          <DialogPreview elements={elements} />
        </Preview>
      </Stack>
      {currentDialogElementEditorItem ? (
        <Drawer
          key={currentDialogElementEditorItem.id}
          title="Element bearbeiten"
          onClose={() => setCurrentDialogElementEditorItemId(undefined)}
          onCloseAll={() => {
            setCurrentDialogElementEditorItemId(undefined);
            onClose();
          }}
        >
          <DialogElementEditor
            item={currentDialogElementEditorItem}
            dialog={item}
            onSave={(updatedElement) => {
              updateElement(updatedElement);
            }}
          />
        </Drawer>
      ) : null}
      {showAddElementDrawer ? (
        <Drawer
          key="add-element"
          title="Element hinzufügen"
          onClose={() => setShowAddElementDrawer(false)}
          onCloseAll={() => {
            setShowAddElementDrawer(false);
            onClose();
          }}
        >
          <DialogElementEditor
            dialog={item}
            onSave={(newElement) => {
              const id = nanoid();
              addElement({ ...newElement, id });
              setShowAddElementDrawer(false);
              setCurrentDialogElementEditorItemId(id);
            }}
          />
        </Drawer>
      ) : null}
    </>
  );
};
