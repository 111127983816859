import { get } from '../request';
import { ApiResponse, WithIds, isErrorResponse } from './common';
import { ApiCompany } from './model';

export type ApiContractCondition = WithIds<{
  name: string;
  leistungsbeschreibung?: string;
}>;

export type ApiContract = WithIds<{
  name: string;
  vertragsTyp: string;
  vertragsbestimmungen: ApiContractCondition[];
  vertragsbestimmungenIds: string[];
}>;

export type ApiContractRelation = WithIds<{
  name: string;
  partei1: ApiCompany;
  partei1Id: string;
  partei2: ApiCompany;
  partei2Id: string;
  vertragsbeziehungsTyp: string;
  vertraege: ApiContract[];
  vertraegeIds: string[];
  vertragsbeziehungen: null;
  vertragsbeziehungenIds: null;
}>;

export type ContractResponse = ApiContractRelation[];

export type Contract = {
  id: string;
  label: string;
};

export type ContractRelation = {
  id: string;
  label: string;
  contracts: Contract[];
};

export type GetContractRelationsProps = {
  companyId: string;
};

export const getContractRelations = async (
  props: GetContractRelationsProps,
): Promise<ContractRelation[]> => {
  const result = await get<ApiResponse<ContractResponse>>(
    '/dialog/vertragsbeziehungenFuerUnternehmenId',
    {
      searchParams: new URLSearchParams({
        unternehmenId: props.companyId,
      }),
    },
  );

  if (isErrorResponse(result)) {
    return [];
  }

  return result.map((contractRelation) => ({
    id: contractRelation._idAsString,
    label: contractRelation.name,
    contracts: contractRelation.vertraege.map((contract) => ({
      id: contract._idAsString,
      label: contract.name,
    })),
  }));
};
