import { FC } from 'react';
import { Skeleton } from '../components/Skeleton';
import { Headline } from '../components/Headline';
import { Navigation } from '../fragments/Navigation';
import { Cockpit } from '../fragments/Cockpit';
import { Copy } from '../components/Copy';

export const FourOhFour: FC = () => {
  return (
    <Skeleton
      intro={<Headline>Fehler</Headline>}
      navigation={<Navigation />}
      cockpit={<Cockpit />}
      main={
        <Copy>Dieses Modul steht Ihnen leider noch nicht zur Verfügung.</Copy>
      }
    />
  );
};
