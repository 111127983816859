import {
  WorkflowBuilderInternalWorkflow,
  WorkflowBuilderInternalWorkflowDialogElement,
  WorkflowBuilderJson,
  WorkflowBuilderJsonConnection,
  WorkflowBuilderJsonDialog,
  WorkflowBuilderJsonDialogElement,
  WorkflowBuilderJsonParty,
  WorkflowBuilderJsonStep,
} from '../fragments/admin/builder/types';
import {
  ApiDialogElementClass,
  getApiDialogElementClassForType,
} from '../services/api/dialogs';
import { isButtonElementType } from './dialogs';

const getAbbreviationForDialogElementClass = (
  elementClass: ApiDialogElementClass,
): string => {
  switch (elementClass) {
    case 'DialogButton':
      return 'db';
    case 'DialogTextField':
      return 'tf';
    case 'DialogHeadline':
      return 'hl';
    case 'DialogSubline':
      return 'sl';
    case 'DialogCommentBox':
      return 'cb';
    case 'DialogFileUpload':
      return 'dfu';
    case 'DialogChoice':
      return 'dc';
    case 'DialogDatePicker':
      return 'dp';
    case 'DialogPersonPicker':
      return 'pp';
    case 'DialogFileDownload':
      return 'fd';
  }
};

class NamespacedIncrementingCounter {
  private prefix: string;
  private counter: number = 0;
  private map = new Map<string, number>();

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  getId(id: string): string {
    const existingId = this.map.get(id);
    if (existingId) {
      return `${this.prefix}_${existingId}`;
    } else {
      this.counter += 1;
      this.map.set(id, this.counter);
      return `${this.prefix}_${this.counter}`;
    }
  }
}

export const convertWorkflowToJsonStructure = (
  workflow: WorkflowBuilderInternalWorkflow,
): WorkflowBuilderJson => {
  const stepCounter = new NamespacedIncrementingCounter('sn');
  const partyCounter = new NamespacedIncrementingCounter('pn');
  const elementCounter = new NamespacedIncrementingCounter('de');
  const dialogCounter = new NamespacedIncrementingCounter('d');

  const getNamespacedElementId = (
    element: WorkflowBuilderInternalWorkflowDialogElement,
  ) =>
    `${elementCounter.getId(element.id)}_${getAbbreviationForDialogElementClass(
      getApiDialogElementClassForType(element.type),
    )}`;

  const steps: WorkflowBuilderJsonStep[] = workflow.steps.map(
    (step) =>
      ({
        id: stepCounter.getId(step.id),
        name: step.name,
      } satisfies WorkflowBuilderJsonStep),
  );
  const parties: WorkflowBuilderJsonParty[] = workflow.steps
    .map((step) =>
      step.parties.map(
        (party) =>
          ({
            id: partyCounter.getId(party.id),
            name: party.name,
            schrittNetzId: stepCounter.getId(step.id),
          } satisfies WorkflowBuilderJsonParty),
      ),
    )
    .flat();
  const dialogElements: WorkflowBuilderJsonDialogElement[] = workflow.steps
    .map((step) =>
      step.parties
        .map((party) =>
          party.dialogs
            .map((dialog) => {
              return dialog.elements.map(
                (element) =>
                  ({
                    id: getNamespacedElementId(element),
                    name: element.name,
                    elementClass: getApiDialogElementClassForType(element.type),
                    dialogElementTyp: element.type,
                    send: element.send,
                    close: element.close,
                    hAlignment:
                      element.type === 'DialogCommentBox'
                        ? element.alignment
                        : undefined,
                    comment: element.comment?.length
                      ? element.comment
                      : undefined,
                    authorImage: element.authorImage || undefined,
                    authorName: element.authorName || undefined,
                  } satisfies WorkflowBuilderJsonDialogElement),
              );
            })
            .flat(),
        )
        .flat(),
    )
    .flat();
  const dialogs: WorkflowBuilderJsonDialog[] = workflow.steps
    .map((step) =>
      step.parties
        .map((party) =>
          party.dialogs
            .map((dialog) => {
              const dialogElements: string[] = dialog.elements
                .filter((element) => !isButtonElementType(element.type))
                .map((element) => getNamespacedElementId(element));
              const dialogButtons: string[] = dialog.elements
                .filter((element) => isButtonElementType(element.type))
                .map((element) => getNamespacedElementId(element));

              return {
                id: dialogCounter.getId(dialog.id),
                name: dialog.name,
                dialogElements,
                dialogButtons,
                parent: partyCounter.getId(party.id),
              } satisfies WorkflowBuilderJsonDialog;
            })
            .flat(),
        )
        .flat(),
    )
    .flat();
  const connections: WorkflowBuilderJsonConnection[] = workflow.steps
    .map((step) =>
      step.parties
        .map((party) =>
          party.dialogs
            .map((dialog) => {
              const button = dialog.elements.find((element) => element.to);

              return {
                from: dialogCounter.getId(dialog.id),
                to: button?.to ? dialogCounter.getId(button.to) : null,
              } satisfies WorkflowBuilderJsonConnection;
            })
            .flat(),
        )
        .flat(),
    )
    .flat();

  return {
    workflows: [
      {
        thema: workflow.name,
        schrittNetze: steps,
        parteiNetze: parties,
        dialogElements,
        dialogs,
        connections,
      },
    ],
  };
};
