import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { IconName, getIcon } from './Icon';

import './State.scss';

export type StateVariant = 'open' | 'closed' | 'blocked' | 'alert' | 'neutral';

export type StateProps = {
  variant?: StateVariant;
  label?: ReactNode;
};

const ICON_MAPPING: Record<StateVariant, IconName> = {
  open: 'Open',
  closed: 'Closed',
  blocked: 'Blocked',
  alert: 'Alert',
  neutral: 'Alert',
};

export const State: FC<StateProps> = ({ variant = 'neutral', label }) => {
  const IconComponent = getIcon(ICON_MAPPING[variant]);

  return (
    <div className={classNames('state', `state--${variant}`)}>
      <div className="state__icon">
        <IconComponent />
      </div>
      {label ? <div className="state__label">{label}</div> : null}
    </div>
  );
};
