import { FC, useEffect, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import {
  WorkflowBuilderInternalWorkflowDialog,
  WorkflowBuilderInternalWorkflowParty,
  WorkflowBuilderInternalWorkflowStep,
} from './types';
import { Stack } from '../../../components/Stack';
import { FormField } from '../../../components/FormField';
import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { ActionButton } from '../../../components/ActionButton';
import { Drawer } from '../../../components/Drawer';
import { DialogEditor } from './DialogEditor';
import { Tile } from '../../../components/Tile';
import { Cluster } from '../../../components/Cluster';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../../components/StrictModeDroppable';
import { useDraggableInPortal } from '../../../utils/dnd';
import { bus } from '../../../utils/bus';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

export type PartyEditorProps = {
  item?: WorkflowBuilderInternalWorkflowParty;
  step?: WorkflowBuilderInternalWorkflowStep;
  onSave: (newItem: WorkflowBuilderInternalWorkflowParty) => void;
  onClose: () => void;
};

export const PartyEditor: FC<PartyEditorProps> = ({
  item,
  onSave,
  onClose,
}) => {
  const renderDraggable = useDraggableInPortal();
  const [name, setName] = useState<string>(item?.name ?? '');
  const [dialogs, setDialogs] = useState<
    WorkflowBuilderInternalWorkflowDialog[]
  >(item?.dialogs ?? []);
  const [showAddDialogDrawer, setShowAddDialogDrawer] =
    useState<boolean>(false);
  const [currentDialogEditorItemId, setCurrentDialogEditorItemId] = useState<
    string | undefined
  >(undefined);
  const currentDialogEditorItem = useMemo(
    () => dialogs.find((dialog) => dialog.id === currentDialogEditorItemId),
    [currentDialogEditorItemId, dialogs],
  );

  const addDialog = (newDialog: WorkflowBuilderInternalWorkflowDialog) => {
    setDialogs((currentDialogs) => [...currentDialogs, newDialog]);
  };

  const updateDialog = (
    updatedDialog: WorkflowBuilderInternalWorkflowDialog,
  ) => {
    setDialogs((currentDialogs) =>
      currentDialogs.map((dialog) =>
        dialog.id === updatedDialog.id ? updatedDialog : dialog,
      ),
    );
  };

  const deleteDialog = (
    deletedParty: WorkflowBuilderInternalWorkflowDialog,
  ) => {
    setDialogs((currentDialogs) =>
      currentDialogs.filter((dialog) => dialog.id !== deletedParty.id),
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const sortingResult = [...dialogs];
    const [removed] = sortingResult.splice(source.index, 1);
    sortingResult.splice(destination.index, 0, removed);
    setDialogs(sortingResult);
  };

  useEffect(() => {
    bus.on('workflow-builder:dialog', ({ dialogId }) => {
      setCurrentDialogEditorItemId(dialogId);
    });
  }, []);

  useDeepCompareEffectNoCheck(() => {
    onSave({
      id: item?.id ?? nanoid(),
      name,
      dialogs,
    });
  }, [item, name, dialogs]);

  return (
    <>
      <form>
        <Stack>
          <FormField id="party_name" label="Interner Name">
            <Input value={name} onChange={(newName) => setName(newName)} />
          </FormField>
          <Tile variant="plain">
            <Stack>
              {dialogs.length ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <StrictModeDroppable droppableId="dialogs">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <Stack>
                          {dialogs.map((dialog, index) => (
                            <Draggable
                              key={dialog.id}
                              draggableId={dialog.id}
                              index={index}
                            >
                              {renderDraggable((provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    userSelect: 'none',
                                  }}
                                >
                                  <Card
                                    key={dialog.id}
                                    title={dialog.name}
                                    actions={
                                      <Cluster>
                                        <ActionButton
                                          variant="ghost"
                                          size="sm"
                                          icon="Cross"
                                          onClick={() => deleteDialog(dialog)}
                                        />
                                        <ActionButton
                                          variant="ghost"
                                          size="sm"
                                          icon="Edit"
                                          onClick={() =>
                                            setCurrentDialogEditorItemId(
                                              dialog.id,
                                            )
                                          }
                                        />
                                      </Cluster>
                                    }
                                  >
                                    {dialog.elements.length} Element
                                    {dialog.elements.length === 1 ? '' : 'e'}
                                  </Card>
                                </div>
                              ))}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </Stack>
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              ) : null}
              <Button
                label="Dialog hinzufügen"
                onClick={() => setShowAddDialogDrawer(true)}
              />
            </Stack>
          </Tile>
        </Stack>
      </form>
      {currentDialogEditorItem ? (
        <Drawer
          key={currentDialogEditorItem.id}
          title="Dialog bearbeiten"
          onClose={() => setCurrentDialogEditorItemId(undefined)}
          onCloseAll={() => {
            setCurrentDialogEditorItemId(undefined);
            onClose();
          }}
        >
          <DialogEditor
            item={currentDialogEditorItem}
            party={item}
            onSave={(updatedDialog) => {
              updateDialog(updatedDialog);
            }}
            onClose={() => onClose()}
          />
        </Drawer>
      ) : null}
      {showAddDialogDrawer ? (
        <Drawer
          key="add-dialog"
          title="Dialog hinzufügen"
          onClose={() => setShowAddDialogDrawer(false)}
          onCloseAll={() => {
            setShowAddDialogDrawer(false);
            onClose();
          }}
        >
          <DialogEditor
            party={item}
            onSave={(newDialog) => {
              const id = nanoid();
              addDialog({ ...newDialog, id });
              setShowAddDialogDrawer(false);
              setCurrentDialogEditorItemId(id);
            }}
            onClose={() => onClose()}
          />
        </Drawer>
      ) : null}
    </>
  );
};
