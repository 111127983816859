import classNames from 'classnames';
import { FC } from 'react';
import { useId } from '../utils/context';

import './Upload.scss';

export type UploadProps = {
  value?: File | File[];
  placeholder?: string;
  accept?: string;
  tabIndex?: number;
  onChange?: (files: File[]) => void;
  disabled?: boolean;
  required?: boolean;
  state?: 'error' | 'warning';
  helpText?: string;
};

export const Upload: FC<UploadProps> = ({
  value,
  placeholder,
  accept,
  tabIndex,
  onChange,
  disabled,
  required,
  state,
  helpText,
}) => {
  const id = useId();

  return (
    <div
      className={classNames('upload', {
        [`upload--${state}`]: state,
      })}
    >
      <div className="upload__box">
        <svg className="upload__visual" viewBox="0 0 61 73" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.295 60.5h40.413a3.853 3.853 0 0 0 2.682-1.081 3.634 3.634 0 0 0 1.11-2.606V17.318L30.792.5H4.295a3.853 3.853 0 0 0-2.683 1.08A3.634 3.634 0 0 0 .5 4.187v52.626c0 .978.4 1.916 1.112 2.607a3.853 3.853 0 0 0 2.683 1.08Z"
            fill="#F2F3FA"
          />
          <path
            d="M48.5 17.329H34.174a3.434 3.434 0 0 1-1.293-.237c-.41-.162-.784-.4-1.099-.703a3.25 3.25 0 0 1-.735-1.06 3.172 3.172 0 0 1-.259-1.253V.5S48.5 17.43 48.5 17.329Z"
            fill="#DCD8EE"
          />
          <path
            d="M34.893 17.314 48.5 30.407V17.329s-13.76.134-13.607-.015Z"
            fill="#C2BED6"
          />
          <rect
            x="30.5"
            y="42.5"
            width="30"
            height="30"
            rx="15"
            fill="#151515"
          />
          <g clipPath="url(#a)">
            <path
              d="m48.501 60.5-3-3m0 0-3 3m3-3v6.75m6.293-1.957A3.75 3.75 0 0 0 50 55.25h-.945a6 6 0 1 0-10.305 5.475"
              stroke="#DCD8EE"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M36.5 48.5h18v18h-18z" />
            </clipPath>
          </defs>
        </svg>

        <div className="upload__label">
          {value
            ? Array.isArray(value)
              ? value.map((item) => item.name).join(', ')
              : value.name
            : placeholder || (
                <>
                  Dateien hier ablegen oder{' '}
                  <span className="upload__select">auswählen</span>
                </>
              )}
        </div>

        <input
          id={id}
          type="file"
          className="upload__element"
          placeholder={placeholder}
          accept={accept}
          tabIndex={tabIndex}
          onChange={(e) => onChange?.(Array.from(e.target.files ?? []))}
          disabled={disabled}
          required={required}
          multiple
        />
      </div>
      {helpText ? <div className="upload__help-text">{helpText}</div> : null}
    </div>
  );
};
