import { useEffect } from 'react';

export const useKeyPress = (
  key: string | undefined,
  handler: () => void,
): void => {
  useEffect(() => {
    const onKeyPressHandler = (event: KeyboardEvent) => {
      const path = event.composedPath();
      const isInput = path.some(
        (element) =>
          element instanceof HTMLInputElement ||
          element instanceof HTMLTextAreaElement,
      );

      if (key && !isInput && event.key.toLowerCase() === key.toLowerCase()) {
        handler();
      }
    };

    document.addEventListener('keypress', onKeyPressHandler);

    return () => {
      document.removeEventListener('keypress', onKeyPressHandler);
    };
  }, [handler, key]);
};
