import { FC, ReactNode } from 'react';
import { Logo } from './Logo';
import { ThemeSwitcher } from './ThemeSwitcher';
import { useTheme } from '../utils/context';

import './Splash.scss';

export type SplashProps = {
  intro?: ReactNode;
  main: ReactNode;
};

export const Splash: FC<SplashProps> = ({ intro, main }) => {
  const { theme, setTheme } = useTheme();

  return (
    <div className="splash">
      <div className="splash__wrapper">
        <div className="splash__header">
          <Logo />
        </div>
        {intro ? <div className="splash__intro">{intro}</div> : null}
        <div className="splash__content">{main}</div>
        <div className="splash__theme">
          <ThemeSwitcher
            theme={theme}
            onChange={(newTheme) => setTheme(newTheme)}
          />
        </div>
      </div>
    </div>
  );
};
