import { FC } from 'react';
import { Button } from '../../components/Button';
import { FormField } from '../../components/FormField';
import { Input } from '../../components/Input';
import { Stack } from '../../components/Stack';
import { Choice } from '../../components/Choice';
import { Workflow } from '../../services/api/workflows';
import { useKeyPress } from '../../utils/hooks';

export type WorkflowFormProps = {
  currentWorkflowId: string | undefined;
  setCurrentWorkflowId: (newWorkflowId: string) => void;
  workflows: Workflow[];
  description: string;
  setDescription: (newDescription: string) => void;
  summary: string;
  setSummary: (newSummary: string) => void;
  onSubmit: () => void;
};

export const WorkflowForm: FC<WorkflowFormProps> = ({
  currentWorkflowId,
  setCurrentWorkflowId,
  workflows,
  description,
  setDescription,
  summary,
  setSummary,
  onSubmit,
}) => {
  useKeyPress('Enter', onSubmit);

  return (
    <Stack>
      <FormField id="workflow" label="Korrespondenz-Typ">
        <Stack>
          {workflows.map((workflow) => (
            <Choice
              key={workflow.id}
              label={workflow.label}
              shortcut={workflow.label.substr(0, 1)}
              selected={workflow.id === currentWorkflowId}
              onClick={() => setCurrentWorkflowId(workflow.id)}
            />
          ))}
        </Stack>
      </FormField>
      <FormField id="description" label="Beschreibung">
        <Input
          value={description}
          onChange={(newDescription) => setDescription(newDescription)}
          placeholder="Beschreibung"
        />
      </FormField>
      <FormField id="summary" label="Kurz-Beschreibung">
        <Input
          value={summary}
          onChange={(newSummary) => setSummary(newSummary)}
          placeholder="Kurz-Beschreibung"
        />
      </FormField>
      <Button
        disabled={!currentWorkflowId || !description || !summary}
        shortcut="Enter"
        label="Weiter"
        onClick={onSubmit}
      />
    </Stack>
  );
};
