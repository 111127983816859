import { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { getIcon, IconName } from './Icon';

import './ActionButton.scss';

export type ActionButtonProps = {
  variant?: 'ghost' | 'prominent' | 'subtle';
  size?: 'sm' | 'md' | 'lg';
  icon: IconName;
  disabled?: boolean;
  indicator?: number | boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ActionButton: FC<ActionButtonProps> = ({
  variant = 'prominent',
  size = 'md',
  icon,
  disabled,
  indicator,
  onClick,
}) => {
  const IconComponent = getIcon(icon);

  return (
    <button
      className={classNames(
        'action-button',
        `action-button--${size}`,
        `action-button--${variant}`,
        {
          'action-button--disabled': disabled,
        },
      )}
      onClick={(e) => {
        if (!disabled) {
          onClick(e);
        }
      }}
      disabled={disabled}
    >
      <span className="action-button__icon">
        <IconComponent size={size === 'sm' ? 16 : 24} />
      </span>
      {indicator ? (
        <span className="action-button__indicator">
          {typeof indicator === 'number' ? indicator : null}
        </span>
      ) : null}
    </button>
  );
};
