import { FC } from 'react';
import classNames from 'classnames';
import { IconName, getIcon } from './Icon';
import { useSkeleton } from '../utils/context';

import './QuickLinks.scss';

export type QuickLinksItem = {
  id: string;
  icon: IconName;
  label: string;
  onClick: () => void;
};

export type QuickLinksProps = {
  items: QuickLinksItem[];
};

export const QuickLinks: FC<QuickLinksProps> = ({ items }) => {
  const { collapsed } = useSkeleton();

  return (
    <nav
      className={classNames('quick-links', {
        'quick-links--collapsed': collapsed,
      })}
    >
      <ul className="quick-links__items">
        {items.map(({ id, icon, label, onClick }) => {
          const IconComponent = icon && getIcon(icon);

          return (
            <li key={id} className="quick-links__item">
              <button className="quick-links__action" onClick={() => onClick()}>
                <span className="quick-links__icon">
                  <IconComponent size={16} />
                </span>
                <span className="quick-links__label">{label}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
