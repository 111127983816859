import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import './Tiles.scss';

export type TilesProps = {
  items: ReactNode;
  highlight?: ReactNode;
  sidebar?: ReactNode;
};

export const Tiles: FC<TilesProps> = ({ items, highlight, sidebar }) => {
  return (
    <div
      className={classNames('tiles', {
        'tiles--highlight': highlight,
        'tiles--sidebar': sidebar,
      })}
    >
      <div className="tiles__items">{items}</div>
      {highlight ? <div className="tiles__highlight">{highlight}</div> : null}
      {sidebar ? <div className="tiles__sidebar">{sidebar}</div> : null}
    </div>
  );
};
