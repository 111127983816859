import { FC, useEffect, useMemo, useState } from 'react';
import { useStickyState } from '../../utils/state';
import {
  WorkflowBuilderInternalWorkflow,
  WorkflowBuilderInternalWorkflowStep,
} from '../../fragments/admin/builder/types';
import { Stack } from '../../components/Stack';
import { FormField } from '../../components/FormField';
import { Input } from '../../components/Input';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { ActionButton } from '../../components/ActionButton';
import { Drawer } from '../../components/Drawer';
import { Cluster } from '../../components/Cluster';
import { Layout } from '../../components/Layout';
import { WorkflowContext } from '../../utils/context/workflow';
import { convertWorkflowToJsonStructure } from '../../utils/workflow-builder';
import { StepEditor } from '../../fragments/admin/builder/StepEditor';
import { Skeleton } from '../../components/Skeleton';
import { Headline } from '../../components/Headline';
import { Navigation } from '../../fragments/Navigation';
import { Cockpit } from '../../fragments/Cockpit';
import { Spacer } from '../../components/Spacer';
import { Tabs } from '../../components/Tabs';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../components/StrictModeDroppable';
import { useDraggableInPortal } from '../../utils/dnd';
import { Visualizer } from '../../fragments/admin/builder/Visualizer';
import { bus } from '../../utils/bus';
import { nanoid } from 'nanoid';
import { downloadStringAsFile } from '../../utils/files';

export const AdminWorkflowBuilder: FC = () => {
  const renderDraggable = useDraggableInPortal();
  const [workflow, setWorkflow] =
    useStickyState<WorkflowBuilderInternalWorkflow>(
      {
        name: '',
        steps: [],
      },
      'workflow-builder:workflow',
    );
  const [currentView, setCurrentView] = useState<'json' | 'visualization'>(
    'visualization',
  );
  const [showAddStepDrawer, setShowAddStepDrawer] = useState<boolean>(false);
  const [currentStepEditorItemId, setCurrentStepEditorItemId] = useState<
    string | undefined
  >(undefined);
  const currentStepEditorItem = useMemo(
    () =>
      (workflow.steps ?? []).find(
        (step) => step.id === currentStepEditorItemId,
      ),
    [currentStepEditorItemId, workflow.steps],
  );
  const convertedWorkflowJsonStructure = useMemo(
    () => convertWorkflowToJsonStructure(workflow),
    [workflow],
  );

  const addStep = (newStep: WorkflowBuilderInternalWorkflowStep) => {
    setWorkflow((currentWorkflow) => ({
      ...currentWorkflow,
      steps: [...(currentWorkflow.steps ?? []), newStep],
    }));
  };

  const updateStep = (updatedStep: WorkflowBuilderInternalWorkflowStep) => {
    setWorkflow((currentWorkflow) => ({
      ...currentWorkflow,
      steps: (currentWorkflow.steps ?? []).map((step) =>
        step.id === updatedStep.id ? updatedStep : step,
      ),
    }));
  };

  const deleteStep = (deletedStep: WorkflowBuilderInternalWorkflowStep) => {
    setWorkflow((currentWorkflow) => ({
      ...currentWorkflow,
      steps: (currentWorkflow.steps ?? []).filter(
        (step) => step.id !== deletedStep.id,
      ),
    }));
  };

  const reset = () => {
    setWorkflow(() => ({
      name: '',
      steps: [],
    }));
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const sortingResult = [...(workflow.steps ?? [])];
    const [removed] = sortingResult.splice(source.index, 1);
    sortingResult.splice(destination.index, 0, removed);
    setWorkflow((currentWorkflow) => ({
      ...currentWorkflow,
      steps: sortingResult,
    }));
  };

  useEffect(() => {
    bus.on('workflow-builder:step', ({ stepId }) => {
      setCurrentStepEditorItemId(stepId);
    });
  }, []);

  return (
    <Skeleton
      intro={
        <Stack gap="2xl">
          <Cluster y="end">
            <Stack>
              <Headline>Workflow Builder</Headline>
            </Stack>
            <Spacer />
            <Button
              icon="Clipboard"
              label="API JSON"
              onClick={async () => {
                await navigator.clipboard.writeText(
                  JSON.stringify(convertedWorkflowJsonStructure, null, 2),
                );
              }}
            />
            <Button
              icon="Clipboard"
              label="Rohdaten"
              onClick={() => {
                downloadStringAsFile(
                  `workflow-${new Date()
                    .toISOString()
                    .replace(/[^\w]/g, '-')}.json`,
                  JSON.stringify(workflow, null, 2),
                  'application/json',
                );
              }}
            />
            <Button
              icon="Alert"
              variant="destructive"
              label="Zurücksetzen"
              onClick={() => {
                reset();
              }}
            />
          </Cluster>
          <Tabs
            items={[
              {
                id: 'visualization',
                label: 'Visualisierung',
                onClick: () => setCurrentView('visualization'),
                active: currentView === 'visualization',
              },
              {
                id: 'json',
                label: 'JSON',
                onClick: () => setCurrentView('json'),
                active: currentView === 'json',
              },
            ]}
          />
        </Stack>
      }
      navigation={<Navigation />}
      cockpit={<Cockpit />}
      main={
        <WorkflowContext.Provider value={workflow}>
          <Layout
            flipped
            sidebar={
              <Stack>
                <FormField id="workflow_name" label="Name">
                  <Input
                    value={workflow.name}
                    onChange={(newName) =>
                      setWorkflow((currentWorkflow) => ({
                        ...currentWorkflow,
                        name: newName,
                      }))
                    }
                  />
                </FormField>
                {workflow.steps?.length ? (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <StrictModeDroppable droppableId="steps">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Stack>
                            {workflow.steps.map((step, index) => (
                              <Draggable
                                key={step.id}
                                draggableId={step.id}
                                index={index}
                              >
                                {renderDraggable((provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      userSelect: 'none',
                                    }}
                                  >
                                    <Card
                                      title={step.name}
                                      actions={
                                        <Cluster>
                                          <ActionButton
                                            variant="ghost"
                                            size="sm"
                                            icon="Cross"
                                            onClick={() => deleteStep(step)}
                                          />
                                          <ActionButton
                                            variant="ghost"
                                            size="sm"
                                            icon="Edit"
                                            onClick={() =>
                                              setCurrentStepEditorItemId(
                                                step.id,
                                              )
                                            }
                                          />
                                        </Cluster>
                                      }
                                    >
                                      {step.parties.length} Partei
                                      {step.parties.length === 1 ? '' : 'en'}
                                    </Card>
                                  </div>
                                ))}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </Stack>
                        </div>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext>
                ) : null}
                <Button
                  label="Schritt hinzufügen"
                  onClick={() => setShowAddStepDrawer(true)}
                />
              </Stack>
            }
          >
            {currentView === 'json' ? (
              <pre>
                {JSON.stringify(convertedWorkflowJsonStructure, null, 2)}
              </pre>
            ) : (
              <Visualizer />
            )}
          </Layout>
          {currentStepEditorItem ? (
            <Drawer
              key={currentStepEditorItem.id}
              title="Schritt bearbeiten"
              onClose={() => setCurrentStepEditorItemId(undefined)}
            >
              <StepEditor
                item={currentStepEditorItem}
                workflow={workflow}
                onSave={(updatedStep) => {
                  updateStep(updatedStep);
                }}
                onClose={() => setCurrentStepEditorItemId(undefined)}
              />
            </Drawer>
          ) : null}
          {showAddStepDrawer ? (
            <Drawer
              key="add-step"
              title="Schritt hinzufügen"
              onClose={() => setShowAddStepDrawer(false)}
            >
              <StepEditor
                workflow={workflow}
                onSave={(newStep) => {
                  const id = nanoid();
                  addStep({ ...newStep, id });
                  setShowAddStepDrawer(false);
                  setCurrentStepEditorItemId(id);
                }}
                onClose={() => setShowAddStepDrawer(false)}
              />
            </Drawer>
          ) : null}
        </WorkflowContext.Provider>
      }
    />
  );
};
