import { createRoot } from 'react-dom/client';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { AuthProvider } from './utils/auth';
import { AuthWall } from './fragments/AuthWall';
import { AdminDashboard } from './pages/admin/Dashboard';
import { AdminWorkflowBuilder } from './pages/admin/WorkflowBuilder';
import { Dashboard } from './pages/Dashboard';
import { Documents } from './pages/Documents';
import { Messaging } from './pages/Messaging';
import { Project } from './pages/Project';
import { Protocols } from './pages/Protocols';
import { Reports } from './pages/Reports';
import { Support } from './pages/Support';
import { Tracking } from './pages/Tracking';
import { FourOhFour } from './pages/FourOhFour';
import { ThemeProvider } from './utils/context';

import './index.scss';

setDefaultOptions({
  locale: de,
});

const root = createRoot(document.getElementById('root') as HTMLElement);

const router = createHashRouter([
  {
    path: '/',
    element: <AuthWall element={<Dashboard />} />,
  },
  {
    path: '/documents/:view?',
    element: <AuthWall element={<Documents />} />,
  },
  {
    path: '/messaging/:view?',
    element: <AuthWall element={<Messaging />} />,
  },
  {
    path: '/project/:view?',
    element: <AuthWall element={<Project />} />,
  },
  {
    path: '/protocols/:view?',
    element: <AuthWall element={<Protocols />} />,
  },
  {
    path: '/reports/:view?',
    element: <AuthWall element={<Reports />} />,
  },
  {
    path: '/support/:view?',
    element: <AuthWall element={<Support />} />,
  },
  {
    path: '/tracking/:view?',
    element: <AuthWall element={<Tracking />} />,
  },
  {
    path: '/admin',
    element: <AuthWall element={<AdminDashboard />} />,
  },
  {
    path: '/admin/builder',
    element: <AuthWall element={<AdminWorkflowBuilder />} />,
  },
  {
    path: '*',
    element: <AuthWall element={<FourOhFour />} />,
  },
]);

root.render(
  <ThemeProvider>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </ThemeProvider>,
);
