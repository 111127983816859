import { get, post } from '../request';
import decode, { JwtPayload } from 'jwt-decode';
import { ApiUser, User } from './model';
import { setRights, setToken, setUser } from '../auth';

export type LoginProps = {
  username: string;
  password: string;
};

export type LoginResponse = ApiUser;

export type LoginResult = {
  token: string | undefined;
  user: User | undefined;
  rights: string[];
};

const parseUserRights = (rights: string[]): string[] =>
  rights.reduce((acc, line) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [id, component, state] = line.split(';');
    if (state.startsWith('VISIBLE_')) {
      return [...acc, id];
    }
    return acc;
  }, [] as string[]);

export const login = async (props: LoginProps): Promise<LoginResult> => {
  const params = new URLSearchParams();
  params.set('nachname', props.username);
  params.set('passwort', props.password);

  const loginResult = await post<LoginResponse>('/login', {
    searchParams: params,
  });

  decode<JwtPayload>(loginResult.sessionToken);

  const token = loginResult.sessionToken;

  const user: User = {
    userId: loginResult._idAsString,
    companyId: loginResult.unternehmenId,
    firstName: loginResult.vorname,
    lastName: loginResult.nachname,
    email: loginResult.email,
    company: undefined,
    role: loginResult.rolle,
  };

  setToken(token);
  setUser(user);

  const rightsResult = await get<string[]>('/berechtigung/loadForRolle', {
    searchParams: new URLSearchParams({
      rolle: loginResult.rolle,
    }),
  });

  const rights = parseUserRights(rightsResult);

  setRights(rights);

  return {
    token,
    user,
    rights,
  };
};
