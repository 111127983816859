import { useEffect, useState } from 'react';
import { getItem, getSessionItem, setItem, setSessionItem } from './storage';

export const useStickyState = <T>(
  defaultValue: T,
  key: string,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = getItem<T>(key);
    return stickyValue ?? defaultValue;
  });

  useEffect(() => {
    setItem(key, value);
  }, [key, value]);

  return [value, setValue];
};

export const useSessionState = <T>(
  defaultValue: T,
  key: string,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const sessionValue = getSessionItem<T>(key);
    return sessionValue ?? defaultValue;
  });

  useEffect(() => {
    setSessionItem(key, value);
  }, [key, value]);

  return [value, setValue];
};
