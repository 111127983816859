import { FC, useState } from 'react';
import { Skeleton } from '../components/Skeleton';
import { Headline } from '../components/Headline';
import { Navigation } from '../fragments/Navigation';
import { Cockpit } from '../fragments/Cockpit';
import { Stack } from '../components/Stack';
import { Copy } from '../components/Copy';
import { Tabs } from '../components/Tabs';
import { Filters } from '../components/Filters';
import { Tile } from '../components/Tile';
import { Card } from '../components/Card';
import { Legend } from '../components/Legend';
import { ActionButton } from '../components/ActionButton';
import { Cluster } from '../components/Cluster';
import { File } from '../components/File';
import { Spacer } from '../components/Spacer';
import { Button } from '../components/Button';
import { Drawer } from '../components/Drawer';

export const Documents: FC = () => {
  const [currentFilter, setCurrentFilter] = useState<string>('all');
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  return (
    <>
      <Skeleton
        intro={
          <Stack gap="2xl">
            <Cluster y="end">
              <Stack>
                <Headline>Dokumente</Headline>
                <Copy>Übersicht und Aktivität über alle Projektdokumente</Copy>
              </Stack>
              <Spacer />
              <Button
                icon="PlusCircle"
                label="Dokument"
                onClick={() => setShowAddModal(true)}
              />
            </Cluster>
            <Tabs
              items={[
                {
                  id: 'overview',
                  to: '/documents',
                  label: 'Aktivität',
                },
                {
                  id: 'versions',
                  to: '/documents/versions',
                  label: 'Aktuelle Versionen',
                },
                {
                  id: 'archive',
                  to: '/documents/archive',
                  label: 'Archiv',
                },
              ]}
            />
          </Stack>
        }
        navigation={<Navigation />}
        cockpit={<Cockpit />}
        main={
          <Filters
            items={[
              {
                id: 'all',
                label: 'Alle',
                indicator: 19,
                onClick: () => setCurrentFilter('all'),
                active: currentFilter === 'all',
              },
              {
                id: 'a',
                label: 'Vertrag',
                indicator: 8,
                onClick: () => setCurrentFilter('a'),
                active: currentFilter === 'a',
              },
              {
                id: 'b',
                label: 'Rechnung',
                indicator: 8,
                onClick: () => setCurrentFilter('b'),
                active: currentFilter === 'b',
              },
              {
                id: 'c',
                label: 'Genehmigung',
                indicator: 2,
                onClick: () => setCurrentFilter('c'),
                active: currentFilter === 'c',
              },
              {
                id: 'd',
                label: 'Plan',
                indicator: 2,
                onClick: () => setCurrentFilter('d'),
                active: currentFilter === 'd',
              },
              {
                id: 'e',
                label: 'Terminplan',
                indicator: 2,
                onClick: () => setCurrentFilter('e'),
                active: currentFilter === 'e',
              },
              {
                id: 'f',
                label: 'Lieferschein',
                indicator: 1,
                onClick: () => setCurrentFilter('f'),
                active: currentFilter === 'f',
              },
            ]}
          >
            <Tile variant="plain">
              <Stack>
                {[
                  'Donnerstag, 08. Juni',
                  'Mittwoch, 07. Juni',
                  'Dienstag, 06. Juni',
                ].map((date) => (
                  <Stack key={date}>
                    <Legend>{date}</Legend>
                    <Stack>
                      <Card
                        title="Vertragsdetails wurden aktualisiert"
                        description="hochgeladen vor 2 Stunden von Paul Popescu"
                        actions={
                          <ActionButton
                            variant="ghost"
                            size="sm"
                            icon="More"
                            onClick={() => undefined}
                          />
                        }
                      >
                        <Cluster>
                          <File
                            href="#"
                            name="vertrag_32243_v2.pdf"
                            size="3 MB"
                          />
                          <File
                            href="#"
                            name="anhang_22332_v2.pdf"
                            size="2 MB"
                          />
                        </Cluster>
                      </Card>
                      <Card
                        title="Dienstleister XY Rechnung hochgeladen"
                        description="hochgeladen vor 2 Stunden von Paul Popescu"
                        actions={
                          <ActionButton
                            variant="ghost"
                            size="sm"
                            icon="More"
                            onClick={() => undefined}
                          />
                        }
                      >
                        <Cluster>
                          <File
                            href="#"
                            name="rechnung_08.06.pdf"
                            size="8 KB"
                          />
                        </Cluster>
                      </Card>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Tile>
          </Filters>
        }
      />
      {showAddModal ? (
        <Drawer
          title="Dokument hochladen"
          onClose={() => setShowAddModal(false)}
        >
          <div>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum.
          </div>
        </Drawer>
      ) : null}
    </>
  );
};
