import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from '../../components/Skeleton';
import { Headline } from '../../components/Headline';
import { Navigation } from '../../fragments/Navigation';
import { Cockpit } from '../../fragments/Cockpit';
import { Copy } from '../../components/Copy';
import { Stack } from '../../components/Stack';
import { Choice } from '../../components/Choice';

export const AdminDashboard: FC = () => {
  const navigate = useNavigate();

  return (
    <Skeleton
      intro={
        <Stack>
          <Headline>Administration</Headline>
          <Copy>
            Du bist ein Administrator, schön! Bitte nutze diese Macht weise.
          </Copy>
        </Stack>
      }
      navigation={<Navigation />}
      cockpit={<Cockpit />}
      main={
        <Stack>
          <Choice
            label="Workflow Builder"
            onClick={() => navigate('/admin/builder')}
          />
        </Stack>
      }
    />
  );
};
