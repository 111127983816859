import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { Spacing } from '../types';

import './Cluster.scss';

export type ClusterProps = PropsWithChildren<{
  gap?: Spacing;
  x?: 'start' | 'center' | 'end';
  y?: 'start' | 'center' | 'end';
  stretched?: boolean;
}>;

export const Cluster: FC<ClusterProps> = ({
  gap = 'md',
  x,
  y,
  stretched,
  children,
}) => {
  return (
    <div
      className={classNames('cluster', `cluster--${gap}`, {
        [`cluster--x-${x}`]: x,
        [`cluster--y-${y}`]: y,
        'cluster--stretched': stretched,
      })}
    >
      {children}
    </div>
  );
};
