import {
  FC,
  ReactNode,
  RefObject,
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  addDays,
  differenceInDays,
  format,
  formatISO,
  isSameDay,
  isWithinInterval,
  parseISO,
  subDays,
} from 'date-fns';
import { TimelineContext, useTimeline } from '../utils/context/timeline';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Clock,
  Dot,
  Play,
  Stop,
  ZoomIn,
  ZoomOut,
} from './Icon';
import { formatInteger } from '../utils/format';

import './Timeline.scss';

const DAYS_TOLERANCE = 1;
const DATE_FORMAT = 'EEEEEE dd.LL.yyyy';
const ZOOM_MIN = 1;
const ZOOM_MAX = 10;

export type TimelineItemProps = {
  id: string;
  label: string;
  startDate: Date | string;
  endDate: Date | string;
  items?: TimelineItemProps[];
  onExpand?: () => void;
};

export type TimelineProps = {
  items: TimelineItemProps[];
  cockpit?: ReactNode;
  now?: Date | string;
};

export type TimelineHeaderItemProps = {
  item: TimelineItemProps;
  level?: number;
  detailed?: boolean;
};

export type TimelineLaneItemProps = {
  item: TimelineItemProps;
};

export const TimelineHeaderItem: FC<TimelineHeaderItemProps> = ({
  item,
  level = 0,
  detailed,
}) => {
  const { expandedItems, setExpandedItems } = useTimeline();
  const isExpanded = useMemo(
    () => expandedItems.includes(item.id),
    [expandedItems, item.id],
  );
  const isExpandable = useMemo(() => Boolean(item.items?.length), [item.items]);
  const ExpandIcom = isExpanded ? ChevronDown : ChevronRight;
  const startDate =
    typeof item.startDate === 'string'
      ? parseISO(item.startDate)
      : item.startDate;
  const endDate =
    typeof item.endDate === 'string' ? parseISO(item.endDate) : item.endDate;

  return (
    <>
      <div
        className={classNames('timeline__header', {
          'timeline__header--detailed': detailed,
          'timeline__header--expanded': isExpanded,
          'timeline__header--expandable': isExpandable,
        })}
      >
        <button
          className={classNames('timeline__navigation', {
            'timeline__navigation--expandable': isExpandable,
            'timeline__navigation--nested': level > 0,
          })}
          onClick={() =>
            setExpandedItems((currentExpandedItems) => {
              if (currentExpandedItems.includes(item.id)) {
                return currentExpandedItems.filter((id) => id !== item.id);
              } else {
                return [...currentExpandedItems, item.id];
              }
            })
          }
        >
          {level > 0 ? (
            <>
              {Array.from({ length: level }).map((_, index) => (
                <span
                  key={index}
                  className="timeline__navigation-icon timeline__navigation-icon--placeholder"
                >
                  <Dot size={16} />
                </span>
              ))}
            </>
          ) : null}
          {isExpandable ? (
            <span className="timeline__navigation-icon">
              <ExpandIcom size={16} />
            </span>
          ) : null}
          <span className="timeline__navigation-cell timeline__navigation-cell--label">
            {item.label}
          </span>
          <hr className="timeline__divider" />
          {detailed ? (
            <>
              <span className="timeline__navigation-cell timeline__navigation-cell--small timeline__navigation-cell--static">
                {item.id}
              </span>
              <hr className="timeline__divider" />
              <span className="timeline__navigation-cell">
                {format(startDate, DATE_FORMAT)}
              </span>
              <hr className="timeline__divider" />
              <span className="timeline__navigation-cell">
                {format(endDate, DATE_FORMAT)}
              </span>
              <hr className="timeline__divider" />
              <span className="timeline__navigation-cell timeline__navigation-cell--medium">
                {formatInteger(differenceInDays(endDate, startDate))} Tage
              </span>
            </>
          ) : null}
        </button>
      </div>
      {isExpanded && isExpandable && item.items?.length
        ? item.items.map((item) => (
            <TimelineHeaderItem
              key={item.id}
              item={item}
              level={level + 1}
              detailed={detailed}
            />
          ))
        : null}
    </>
  );
};

export const TimelineLaneItem: FC<TimelineLaneItemProps> = ({ item }) => {
  const { expandedItems, range, minDate } = useTimeline();
  const isExpanded = useMemo(
    () => expandedItems.includes(item.id),
    [expandedItems, item.id],
  );

  return (
    <>
      <div className="timeline__lane">
        <div className="timeline__cells">
          {Array.from({ length: range }).map((_, index) => {
            const date = addDays(minDate, index);
            const isActive = isWithinInterval(date, {
              start:
                typeof item.startDate === 'string'
                  ? parseISO(item.startDate)
                  : item.startDate,
              end:
                typeof item.endDate === 'string'
                  ? parseISO(item.endDate)
                  : item.endDate,
            });

            return (
              <div
                key={index}
                className={classNames('timeline__cell', {
                  'timeline__cell--active': isActive,
                })}
                title={format(date, DATE_FORMAT)}
              />
            );
          })}
        </div>
      </div>
      {isExpanded && item.items?.length
        ? item.items.map((item) => (
            <TimelineLaneItem key={item.id} item={item} />
          ))
        : null}
    </>
  );
};

export const Timeline: FC<TimelineProps> = ({
  items,
  cockpit,
  now: passedNow,
}) => {
  const minDate = subDays(
    new Date(
      Math.min(...items.map((item) => new Date(item.startDate).getTime())),
    ),
    DAYS_TOLERANCE,
  );
  const maxDate = addDays(
    new Date(
      Math.max(...items.map((item) => new Date(item.endDate).getTime())),
    ),
    DAYS_TOLERANCE,
  );
  const now = useMemo(
    () => (passedNow ? new Date(passedNow) : new Date()),
    [passedNow],
  );
  const range = useMemo(
    () => differenceInDays(maxDate, minDate),
    [maxDate, minDate],
  );
  const [isDetailed, setIsDetailed] = useState<boolean>(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(minDate);
  const lanesRef = useRef<HTMLDivElement | null>(null);
  const [legendRefs, setLegendRefs] = useState<RefObject<HTMLDivElement>[]>([]);
  const [zoomLevel, setZoomLevel] = useState<number>(ZOOM_MAX);

  const switchToZoomLevel = (newZoomLevel: number): void => {
    setZoomLevel(newZoomLevel);
    if (lanesRef.current) {
      const center =
        lanesRef.current.scrollLeft + lanesRef.current.clientWidth / 2;
      const nextBestItem = legendRefs.find(
        (legend) => legend.current && legend.current?.offsetLeft >= center,
      );
      window.setTimeout(() => {
        nextBestItem?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      });
    }
  };

  useEffect(() => {
    setLegendRefs((currentLegendRefs) =>
      Array.from({ length: range }).map(
        (_, i) => currentLegendRefs[i] ?? createRef(),
      ),
    );
  }, [range]);

  return (
    <TimelineContext.Provider
      value={{
        expandedItems,
        setExpandedItems,
        minDate,
        maxDate,
        now,
        range,
      }}
    >
      <div
        className="timeline"
        style={{
          '--timeline-zoom-level': String(zoomLevel),
          '--timeline-zoom-depth': String(ZOOM_MAX),
        }}
      >
        <div
          className={classNames('timeline__overview', {
            'timeline__overview--detailed': isDetailed,
          })}
        >
          <div className="timeline__headers">
            <div
              className={classNames(
                'timeline__header',
                'timeline__header--expander',
                {
                  'timeline__header--detailed': isDetailed,
                },
              )}
            >
              <div className="timeline__expander">
                <span className="timeline__navigation-cell timeline__navigation-cell--label">
                  Vorgang
                </span>
                <hr className="timeline__divider" />
                {isDetailed ? (
                  <>
                    <span className="timeline__navigation-cell timeline__navigation-cell--small timeline__navigation-cell--static">
                      PSP
                    </span>
                    <hr className="timeline__divider" />
                    <span className="timeline__navigation-cell timeline__navigation-cell--icon">
                      <span className="timeline__navigation-cell-icon">
                        <Play size={20} />
                      </span>
                      Start
                    </span>
                    <hr className="timeline__divider" />
                    <span className="timeline__navigation-cell timeline__navigation-cell--icon">
                      <span className="timeline__navigation-cell-icon">
                        <Stop size={20} />
                      </span>
                      Ende
                    </span>
                    <hr className="timeline__divider" />
                    <span className="timeline__navigation-cell timeline__navigation-cell--medium timeline__navigation-cell--icon">
                      <span className="timeline__navigation-cell-icon">
                        <Clock size={20} />
                      </span>
                      Dauer
                    </span>
                  </>
                ) : null}
                <button
                  className="timeline__button"
                  onClick={() =>
                    setIsDetailed((currentIsDetailed) => !currentIsDetailed)
                  }
                >
                  {isDetailed ? <ChevronLeft /> : <ChevronRight />}
                </button>
              </div>
            </div>
            {items.map((item) => (
              <TimelineHeaderItem
                key={item.id}
                item={item}
                detailed={isDetailed}
              />
            ))}
          </div>
          <div className="timeline__content">
            <div className="timeline__intro">
              <div className="timeline__date">
                <strong>{format(currentMonth, 'MMMM')}</strong>{' '}
                {format(currentMonth, 'yyyy')}
              </div>
              {cockpit ? (
                <div className="timeline__cockpit">{cockpit}</div>
              ) : null}
            </div>
            <div
              ref={lanesRef}
              className="timeline__lanes"
              onScroll={(e) => {
                const scrolled = e.currentTarget.scrollLeft;
                const firstVisibleLegend = legendRefs.find(
                  (legendRef) =>
                    legendRef?.current &&
                    legendRef.current.offsetLeft >= scrolled,
                );
                const date =
                  firstVisibleLegend?.current?.getAttribute('data-date');
                if (date) {
                  setCurrentMonth(parseISO(date));
                }
              }}
            >
              <div className="timeline__calendar">
                <div className="timeline__legends">
                  {Array.from({ length: range }).map((_, index) => {
                    const date = addDays(minDate, index);
                    return (
                      <div
                        key={index}
                        ref={legendRefs[index]}
                        className={classNames('timeline__legend', {
                          'timeline__legend--today': isSameDay(date, now),
                        })}
                        data-date={formatISO(date, {
                          representation: 'date',
                        })}
                      >
                        <span className="timeline__legend-index">
                          {format(date, 'dd')}
                        </span>{' '}
                        <span className="timeline__legend-name">
                          {format(date, 'EEEEEE')}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              {items.map((item) => (
                <TimelineLaneItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
        <div className="timeline__zoom">
          <div className="timeline__zoom-controls">
            <button
              className="timeline__zoom-control"
              onClick={() =>
                switchToZoomLevel(Math.max(ZOOM_MIN, zoomLevel - 1))
              }
            >
              <ZoomOut />
            </button>
            <input
              className="timeline__zoom-range"
              type="range"
              min={ZOOM_MIN}
              max={ZOOM_MAX}
              value={zoomLevel}
              onChange={(e) => switchToZoomLevel(parseInt(e.target.value, 10))}
            />
            <button
              className="timeline__zoom-control"
              onClick={() =>
                switchToZoomLevel(Math.min(ZOOM_MAX, zoomLevel + 1))
              }
            >
              <ZoomIn />
            </button>
          </div>
        </div>
      </div>
    </TimelineContext.Provider>
  );
};
