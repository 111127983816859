import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { IdContext } from '../utils/context';

import './FormField.scss';

export type FormFieldProps = PropsWithChildren<{
  id: string;
  label?: ReactNode;
  error?: string;
  help?: string;
}>;

export const FormField: FC<FormFieldProps> = ({
  id,
  label,
  error,
  help,
  children,
}) => {
  return (
    <div
      className={classNames('form-field', {
        'form-field--erroneous': error,
      })}
    >
      <div className="form-field__content">
        {label ? (
          <label className="form-field__label" htmlFor={id}>
            {label}
          </label>
        ) : null}
        <IdContext.Provider value={id}>{children}</IdContext.Provider>
      </div>
      {error ? <div className="form-field__error">{error}</div> : null}
      {help ? <div className="form-field__help">{help}</div> : null}
    </div>
  );
};
