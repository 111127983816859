import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import './Layout.scss';

export type LayoutProps = PropsWithChildren<{
  sidebar?: ReactNode;
  flipped?: boolean;
}>;

export const Layout: FC<LayoutProps> = ({ sidebar, children, flipped }) => {
  return (
    <div
      className={classNames('layout', {
        'layout--flipped': flipped,
      })}
    >
      {sidebar ? <div className="layout__sidebar">{sidebar}</div> : null}
      <div className="layout__content">{children}</div>
    </div>
  );
};
