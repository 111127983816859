import { FC, useState } from 'react';
import { login } from '../services/api';
import { Stack } from '../components/Stack';
import { Headline } from '../components/Headline';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { Splash } from '../components/Splash';
import { FormField } from '../components/FormField';
import { useAuth } from '../utils/auth';
import { Hint } from '../components/Hint';

export const Login: FC = () => {
  const { handleLogin } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return (
    <Splash
      intro={<Headline>Willkommen</Headline>}
      main={
        <Stack>
          {error ? <Hint variant="negative" title={error} /> : null}
          <form>
            <Stack>
              <FormField id="username" label="Benutzername">
                <Input
                  value={username}
                  onChange={(newUsername) => setUsername(newUsername)}
                  placeholder="Benutzername"
                  autoComplete="username"
                />
              </FormField>
              <FormField id="password" label="Passwort">
                <Input
                  type="password"
                  value={password}
                  onChange={(newPassword) => setPassword(newPassword)}
                  placeholder="Passwort"
                  autoComplete="password"
                />
              </FormField>
              <Button
                type="submit"
                loading={isLoading}
                label="Anmelden"
                onClick={async () => {
                  setIsLoading(true);
                  setError(undefined);

                  try {
                    const response = await login({
                      username,
                      password,
                    });

                    if (response.token && response.user) {
                      handleLogin(
                        response.token,
                        response.user,
                        response.rights,
                      );
                    }
                  } catch (e) {
                    if (typeof e === 'string') {
                      setError(e.replaceAll('"', ''));
                    } else {
                      console.error(e);
                    }
                  } finally {
                    setIsLoading(false);
                  }
                }}
              />
            </Stack>
          </form>
        </Stack>
      }
    />
  );
};
