import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import './Copy.scss';

export type CopyVariant = 'subordinate' | 'emphasized';

export type CopyProps = PropsWithChildren<{
  variant?: CopyVariant;
  strong?: boolean;
}>;

export const Copy: FC<CopyProps> = ({ variant, strong, children }) => {
  return (
    <span
      className={classNames('copy', {
        [`copy--${variant}`]: variant,
        'copy--strong': strong,
      })}
    >
      {children}
    </span>
  );
};
