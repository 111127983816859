import { FC, PropsWithChildren, ReactNode, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import './Flyout.scss';

import { ActionButton } from './ActionButton';

export type FlyoutProps = PropsWithChildren<{
  title?: ReactNode;
  trigger: ReactNode;
  open?: boolean;
  onClose: () => void;
}>;

export const Flyout: FC<FlyoutProps> = ({
  title,
  trigger,
  open,
  onClose,
  children,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(ref, onClose);

  return (
    <div className="flyout">
      <div className="flyout__content">
        <div className="flyout__trigger">{trigger}</div>
        {open ? (
          <div className="flyout__panel" ref={ref}>
            <div className="flyout__header">
              <div className="flyout__title">{title || null}</div>
              <div className="flyout__actions">
                <ActionButton
                  variant="ghost"
                  icon="Cross"
                  size="sm"
                  onClick={() => onClose()}
                />
              </div>
            </div>
            <div className="flyout__body">{children}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
