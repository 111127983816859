import { FC } from 'react';
import { Theme } from '../utils/context';

import './ThemeSwitcher.scss';
import { Moon, Sun } from './Icon';
import classNames from 'classnames';

export type ThemeSwitcherProps = {
  theme?: Theme;
  onChange: (newTheme: Theme) => void;
};

export const ThemeSwitcher: FC<ThemeSwitcherProps> = ({ theme, onChange }) => {
  return (
    <button
      className={classNames('theme-switcher', `theme-switcher--${theme}`)}
      onClick={() => {
        onChange(theme === 'light' ? 'dark' : 'light');
      }}
    >
      <span className="theme-switcher__content">
        <span className="theme-switcher__icon theme-switcher__icon--light">
          <Sun size={20} />
        </span>
        <span className="theme-switcher__indicator" />
        <span className="theme-switcher__icon theme-switcher__icon--dark">
          <Moon size={20} />
        </span>
      </span>
    </button>
  );
};
