import { FC } from 'react';
import classNames from 'classnames';

import './VisualizerDivider.scss';

export type VisualizerDividerProps = {
  variant?: 'start' | 'default' | 'end';
  size?: 'small' | 'large';
};

export const VisualizerDivider: FC<VisualizerDividerProps> = ({
  variant = 'default',
  size = 'small',
}) => (
  <svg
    className={classNames(
      'visualizer-divider',
      `visualizer-divider--${variant}`,
      `visualizer-divider--${size}`,
    )}
  >
    {variant === 'start' ? (
      <line
        className="visualizer-divider__line"
        x1="0"
        y1="100%"
        x2="100%"
        y2="0"
      />
    ) : variant === 'end' ? (
      <line
        className="visualizer-divider__line"
        x1="0"
        y1="0"
        x2="100%"
        y2="100%"
      />
    ) : (
      <line
        className="visualizer-divider__line"
        x1="50%"
        y1="0"
        x2="50%"
        y2="100%"
      />
    )}
  </svg>
);

export const getVisualizerDividerVariantForIndex = (
  index: number,
  array: unknown[],
) => {
  if (array.length === 1) {
    return 'default';
  }

  switch (index) {
    case 0:
      return 'start';
    case array.length - 1:
      return 'end';
    default:
      return 'default';
  }
};
