import { post, put } from '../request';
import { ApiResponse, WithIds, isErrorResponse } from './common';
import { ApiContractRelation } from './contracts';

export const DIALOG_ELEMENT_CLASSES = [
  'DialogButton',
  'DialogTextField',
  'DialogHeadline',
  'DialogSubline',
  'DialogCommentBox',
  'DialogFileUpload',
  'DialogChoice',
  'DialogDatePicker',
  'DialogPersonPicker',
  'DialogFileDownload',
] as const;

export type ApiDialogElementClass = (typeof DIALOG_ELEMENT_CLASSES)[number];

export const isValidApiDialogElementClass = (
  value: unknown,
): value is ApiDialogElementClass => {
  return DIALOG_ELEMENT_CLASSES.some((item) => item === value);
};

export const DIALOG_ELEMENT_TYPES = [
  'DialogButton',
  'DialogTextField',
  'DialogBlätternWeiter',
  'DialogBlätternZurück',
  'DialogButtonSchließen',
  'DialogButtonSenden',
  'DialogHeadline',
  'DialogSubline',
  'DialogCommentBox',
  'DialogFileUpload',
  'DialogChoice',
  'DialogDatePicker',
  'DialogPersonPicker',
  'DialogFileDownload',
] as const;

export type ApiDialogElementType = (typeof DIALOG_ELEMENT_TYPES)[number];

export const isValidApiDialogElementType = (
  value: unknown,
): value is ApiDialogElementType => {
  return DIALOG_ELEMENT_TYPES.some((item) => item === value);
};

export type ApiDialogElementDetails = WithIds<{
  medienIds: never[];
  dialogElementId: string;
  name: string;
  titel?: string;
  buttonText?: string;
  workflowId: string;
  horAlignment?: 'LEFT' | 'RIGHT';
  comments?: string[];
  authorImage?: string;
  authorName?: string;
  send?: boolean;
  close?: boolean;
  dialogElementTyp: ApiDialogElementType;
  analyticsFeatureIds: never[];
}>;

export type ApiDialogElement = {
  dialogElement: ApiDialogElementDetails;
  elementObjectId: string;
  elementClass: ApiDialogElementType;
};

export type ApiDialogButton = {
  dialogElement: ApiDialogElementDetails;
  elementObjectId: string;
  elementClass: ApiDialogElementType;
};

export type ApiDialogEntryContent = WithIds<{
  key: string;
  dialogKey: string;
  dialogType: string;
  value: string;
}>;

export type ApiDialogEntry = WithIds<{
  rolle: string;
  content: ApiDialogEntryContent[];
  dialogId: string;
  name: string;
}>;

export type ApiDialog = WithIds<{
  dialogId: string;
  name: string;
  dialogElements: ApiDialogElement[];
  dialogChoices: ApiDialogElement[];
  dialogButtons: ApiDialogButton[];
  tokenInPlace: {
    workflowId: string;
    workflowThema: string;
    beschreibung: string;
    kurzBezeichnung: string;
    vertragsbeziehung: ApiContractRelation;
    parteiId: string;
    currentDialogId: string;
    eintraege: ApiDialogEntry[];
    report: string[];
  };
}>;

export type ApiDialogField = WithIds<{
  dialogId: string;
  name: string;
  text: string;
  buttonText?: string;
}>;

export type ApiDialogButtonFireResult = {
  Dialog: ApiDialog;
  Console: string[];
};

export type DialogResponse = ApiDialog;

export type DialogElement = {
  id: string;
  elementId: string;
  objectId: string;
  type: ApiDialogElementType;
  name: string;
  alignment?: 'LEFT' | 'RIGHT';
  comments?: string[];
  authorImage?: string;
  authorName?: string;
};

export type DialogStepItem = {
  id: string;
  elementId: string;
  type: string;
  value: string;
};

export type DialogStep = {
  id: string;
  name: string;
  items: DialogStepItem[];
};

export type Dialog = {
  id: string;
  label: string;
  elements: DialogElement[];
  buttons: DialogElement[];
  meta: {
    workflow: string;
    contractRelation: string;
    description: string;
    summary: string;
    steps: DialogStep[];
  };
};

export const getApiDialogElementClassForType = (
  type: ApiDialogElementType,
): ApiDialogElementClass => {
  switch (type) {
    case 'DialogButton':
    case 'DialogBlätternWeiter':
    case 'DialogBlätternZurück':
    case 'DialogButtonSchließen':
    case 'DialogButtonSenden':
      return 'DialogButton';
    case 'DialogTextField':
      return 'DialogTextField';
    case 'DialogHeadline':
      return 'DialogHeadline';
    case 'DialogSubline':
      return 'DialogSubline';
    case 'DialogCommentBox':
      return 'DialogCommentBox';
    case 'DialogFileUpload':
      return 'DialogFileUpload';
    case 'DialogChoice':
      return 'DialogChoice';
    case 'DialogDatePicker':
      return 'DialogDatePicker';
    case 'DialogPersonPicker':
      return 'DialogPersonPicker';
    case 'DialogFileDownload':
      return 'DialogFileDownload';
  }
};

const convertApiDialogElementToDialogElement = (
  result: ApiDialogElement,
): DialogElement => ({
  id: result.dialogElement._idAsString,
  elementId: result.dialogElement.dialogElementId,
  objectId: result.elementObjectId,
  type: result.dialogElement.dialogElementTyp,
  name: result.dialogElement.name,
  alignment: result.dialogElement.horAlignment,
  comments: result.dialogElement.comments,
  authorImage: result.dialogElement.authorImage,
  authorName: result.dialogElement.authorName,
});

const convertApiDialogToDialog = (result: ApiDialog): Dialog => {
  return {
    id: result._idAsString,
    label: result.name,
    elements: [
      ...result.dialogElements.map((element) =>
        convertApiDialogElementToDialogElement(element),
      ),
      ...result.dialogChoices.map((element) =>
        convertApiDialogElementToDialogElement(element),
      ),
    ],
    buttons: result.dialogButtons.map((element) =>
      convertApiDialogElementToDialogElement(element),
    ),
    meta: {
      workflow: result.tokenInPlace.workflowThema,
      contractRelation: result.tokenInPlace.vertragsbeziehung.name,
      description: result.tokenInPlace.beschreibung,
      summary: result.tokenInPlace.kurzBezeichnung,
      steps: result.tokenInPlace.eintraege.map((entry) => ({
        id: entry._idAsString,
        name: entry.name,
        items: entry.content.map((content) => ({
          id: content._idAsString,
          elementId: content.dialogKey,
          type: content.dialogType,
          value: content.value,
        })),
      })),
    },
  };
};

export type StartDialogProps = {
  workflowId: string;
  workflowObjectId: string;
  contractRelationId: string;
  description: string;
  summary: string;
};

export const startDialog = async (props: StartDialogProps): Promise<Dialog> => {
  const result = await put<ApiResponse<DialogResponse>>('/dialog/buildDialog', {
    searchParams: new URLSearchParams({
      workflowId: props.workflowId,
      workflowObjectId: props.workflowObjectId,
      vertragsbeziehungId: props.contractRelationId,
      beschreibung: props.description,
      kurzBeschreibung: props.summary,
    }),
  });

  if (isErrorResponse(result)) {
    return Promise.reject();
  }

  return convertApiDialogToDialog(result);
};

export type SetDialogFieldValueProps = {
  dialogId: string;
  elementId: string;
  value: string;
};

export const setDialogFieldValue = async (
  props: SetDialogFieldValueProps,
): Promise<void> => {
  const result = await put<ApiResponse<ApiDialogField>>(
    '/dialog/updateDialogTextField',
    {
      searchParams: new URLSearchParams({
        dialogId: props.dialogId,
        dialogElementId: props.elementId,
        text: props.value,
      }),
    },
  );

  if (isErrorResponse(result)) {
    return Promise.reject();
  }
};

export type FireDialogButtonProps = {
  dialogId: string;
  elementId: string;
};

export const fireDialogButton = async (
  props: FireDialogButtonProps,
): Promise<Dialog> => {
  const result = await put<ApiResponse<ApiDialogButtonFireResult>>(
    '/dialog/dialogButtonFire',
    {
      searchParams: new URLSearchParams({
        dialogId: props.dialogId,
        dialogElementId: props.elementId,
      }),
    },
  );

  if (isErrorResponse(result)) {
    return Promise.reject();
  }

  return convertApiDialogToDialog(result.Dialog);
};

export type UploadDialogFieldFileProps = {
  dialogId: string;
  elementId: string;
  file: File;
};

export const uploadDialogFieldFile = async (
  props: UploadDialogFieldFileProps,
): Promise<void> => {
  const data = new FormData();
  data.set('upfile', props.file);

  const result = await post<ApiResponse<ApiDialogField>>(
    '/dialog/updateDialogFileUpload',
    {
      data,
      searchParams: new URLSearchParams({
        dialogId: props.dialogId,
        dialogElementId: props.elementId,
        dateiname: props.file.name,
      }),
    },
  );

  if (isErrorResponse(result)) {
    return Promise.reject();
  }
};
