import { FC, useState } from 'react';
import { Skeleton } from '../components/Skeleton';
import { Headline } from '../components/Headline';
import { Navigation } from '../fragments/Navigation';
import { Cockpit } from '../fragments/Cockpit';
import { Stack } from '../components/Stack';
import { Copy } from '../components/Copy';
import { Tabs } from '../components/Tabs';
import { Cluster } from '../components/Cluster';
import { Spacer } from '../components/Spacer';
import { Button } from '../components/Button';
import { Drawer } from '../components/Drawer';
import { Calendar } from '../components/Calendar';
import { getCalendarMockData } from '../utils/mocks';

export const Reports: FC = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const mockData = getCalendarMockData();

  return (
    <>
      <Skeleton
        intro={
          <Stack gap="2xl">
            <Cluster y="end">
              <Stack>
                <Headline>Bautagesberichte</Headline>
                <Copy>Übersicht und Aktivität über alle Projektdokumente</Copy>
              </Stack>
              <Spacer />
              <Button
                icon="PlusCircle"
                label="Bautagesbericht"
                onClick={() => setShowAddModal(true)}
              />
            </Cluster>
            <Tabs
              items={[
                {
                  id: 'calendar',
                  to: '/reports',
                  label: 'Kalender',
                },
                {
                  id: 'list',
                  to: '/reports/list',
                  label: 'Liste',
                },
              ]}
            />
          </Stack>
        }
        navigation={<Navigation />}
        cockpit={<Cockpit />}
        main={<Calendar data={mockData} />}
      />
      {showAddModal ? (
        <Drawer
          title="Bautagesbericht erstellen"
          onClose={() => setShowAddModal(false)}
        >
          <div>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum.
          </div>
        </Drawer>
      ) : null}
    </>
  );
};
