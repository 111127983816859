import { getUnixTime } from 'date-fns';
import decode, { JwtPayload } from 'jwt-decode';
import {
  getSessionItem,
  removeSessionItem,
  setSessionItem,
} from '../utils/storage';
import { User } from './api/model';

export const isTokenValid = (): boolean => {
  const currentToken = getSessionItem<string | undefined>('session');
  if (currentToken) {
    const decoded = decode<JwtPayload>(currentToken);
    const now = getUnixTime(new Date());
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
      return false;
    }
    if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
      return false;
    }
    return true;
  }
  return false;
};

export const getTokenValidity = (): number => {
  const currentToken = getSessionItem<string | undefined>('session');
  if (currentToken) {
    const decoded = decode<JwtPayload>(currentToken);
    if (typeof decoded.exp !== 'undefined') {
      return Number(decoded.exp) || 0;
    }
  }
  return 0;
};

export const hasToken = (): boolean => {
  const currentToken = getSessionItem<string | undefined>('session');
  return Boolean(currentToken);
};

export const hasValidToken = (): boolean => {
  const currentToken = getSessionItem<string | undefined>('session');
  return Boolean(currentToken) && isTokenValid();
};

export const getToken = (): string | undefined => {
  const currentToken = getSessionItem<string | undefined>('session');
  return hasValidToken() ? currentToken : undefined;
};

export const setToken = (newToken: string): void => {
  setSessionItem('session', newToken);
  setSessionItem('session', newToken);
};

export const clearToken = (): void => {
  removeSessionItem('session');
  removeSessionItem('session');
};

export const getUser = (): User | undefined => getSessionItem<User>('user');

export const setUser = (newUser: User): void => {
  setSessionItem('user', newUser);
};

export const clearUser = (): void => {
  removeSessionItem('user');
};

export const getRights = (): string[] | undefined =>
  getSessionItem<string[]>('rights');

export const setRights = (newRights: string[]): void => {
  setSessionItem('rights', newRights);
};

export const clearRights = (): void => {
  removeSessionItem('rights');
};
