import { ApiDate, convertApiDateToDate } from '../../utils/api';
import { get } from '../request';
import { Mapped, ApiResponse, isErrorResponse } from './common';

export type ApiEvent = Mapped<{
  baubereich: string;
  start: Mapped<{
    date: {
      myArrayList: ApiDate;
    };
    sourceClass: string;
    tag: 'start';
  }>;
  end: Mapped<{
    date: {
      myArrayList: ApiDate;
    };
    sourceClass: string;
    tag: 'end';
  }>;
  psp: string;
  zeile: number;
  terminplanBezeichnung: string;
  vorgangsDauer: number;
  unternehmenKurzform: string;
  taskName: string;
  isCritical: boolean;
  id: Mapped<{
    date: string;
    timestamp: number;
  }>;
  isSammelBalken: boolean;
  taskId: number;
}>;

export type EventResponse = Mapped<{
  Vorgänge: Mapped<Record<string, ApiEvent>>;
}>;

export type Event = {
  id: string;
  label: string;
  startDate: Date;
  endDate: Date;
  items?: Event[];
  level: number;
};

const EVENT_LEVEL_DIVIDER = '.';

const getSortFactorForEvent = (id: string): number =>
  id.split(EVENT_LEVEL_DIVIDER).length;

const convertApiEventToEvent = (item: ApiEvent): Event => ({
  id: item.map.psp,
  label: item.map.taskName,
  startDate: convertApiDateToDate(item.map.start.map.date.myArrayList),
  endDate: convertApiDateToDate(item.map.end.map.date.myArrayList),
  level: getSortFactorForEvent(item.map.psp),
});

const convertEventsToTree = (sortedEvents: Event[], level: number): Event[] => {
  const filtered = sortedEvents
    .filter((item) => item.level === level)
    .map((item) => ({
      ...item,
      items: convertEventsToTree(
        sortedEvents.filter((subItem) =>
          subItem.id.startsWith(`${item.id}${EVENT_LEVEL_DIVIDER}`),
        ),
        level + 1,
      ),
    }));
  return filtered;
};

export const getEvents = async (): Promise<Event[]> => {
  const result = await get<ApiResponse<EventResponse>>('/vorgang/vorgaenge');

  if (isErrorResponse(result)) {
    return [];
  }

  const events = Object.values(result.map.Vorgänge.map).map((item) =>
    convertApiEventToEvent(item),
  );
  const sorted = events.sort((a, b) => a.level - b.level);
  const level = Math.min(...sorted.map((item) => item.level));

  return convertEventsToTree(sorted, level);
};
