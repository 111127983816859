import { FC } from 'react';
import { Document } from './Icon';

import './File.scss';

export type FileProps = {
  href: string;
  name: string;
  size?: string;
};

export const File: FC<FileProps> = ({ href, name, size }) => {
  return (
    <a href={href} download={name} className="file">
      <span className="file__icon">
        <Document />
      </span>
      <span className="file__name">{name}</span>
      {size ? <span className="file__size">{size}</span> : null}
    </a>
  );
};
