import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { useId } from '../utils/context';
import { ChevronDown } from './Icon';

import './Select.scss';

export type SelectOptionProps = {
  value?: string;
  label: ReactNode;
};

export type SelectProps = {
  value?: string | number;
  options: SelectOptionProps[];
  tabIndex?: number;
  onChange?: (newValue: string | undefined) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  required?: boolean;
  nullable?: boolean;
  state?: 'error' | 'warning';
  helpText?: string;
};

export const Select: FC<SelectProps> = ({
  value,
  options,
  tabIndex,
  onChange,
  onFocus,
  onBlur,
  disabled,
  required,
  nullable,
  state,
  helpText,
}) => {
  const id = useId();

  return (
    <div
      className={classNames('select', {
        [`select--${state}`]: state,
      })}
    >
      <div className="select__box">
        <select
          id={id}
          className="select__element"
          value={value}
          tabIndex={tabIndex}
          onChange={(e) => onChange?.(e.target.value)}
          onFocus={() => onFocus?.()}
          onBlur={() => onBlur?.()}
          disabled={disabled}
          required={required}
        >
          <option hidden={!nullable} disabled={!nullable}>
            –
          </option>
          {options.map(({ value, label }) => (
            <option key={value ?? 'default'} value={value}>
              {label}
            </option>
          ))}
        </select>
        <div className="select__indicator">
          <ChevronDown />
        </div>
      </div>
      {helpText ? <div className="select__help-text">{helpText}</div> : null}
    </div>
  );
};
