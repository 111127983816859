import { FC } from 'react';
import { Dialog } from '../../services/api/dialogs';
import { Stack } from '../../components/Stack';
import { Headline } from '../../components/Headline';
import { useAuth } from '../../utils/auth';
import { Tile } from '../../components/Tile';
import { Legend } from '../../components/Legend';
import { Card } from '../../components/Card';
import { Cluster } from '../../components/Cluster';
import { Spacer } from '../../components/Spacer';
import { Badge } from '../../components/Badge';
import { isFormFieldElementType } from '../../utils/dialogs';

export type DialogSummaryProps = {
  dialog: Dialog;
};

export const DialogSummary: FC<DialogSummaryProps> = ({ dialog }) => {
  const auth = useAuth();

  return (
    <Stack>
      <Stack gap="none">
        {auth.user ? <Headline>Danke, {auth.user.firstName}!</Headline> : null}
        <Headline>Bitte überprüfe deine Angaben.</Headline>
      </Stack>
      <Tile variant="plain">
        <Stack>
          <Stack>
            <Legend>{dialog.meta.contractRelation}</Legend>
            <Card
              title={dialog.meta.description}
              description={dialog.meta.summary}
            >
              <Cluster stretched>
                <Spacer />
                <Cluster>
                  <Badge label={dialog.meta.workflow} />
                </Cluster>
              </Cluster>
            </Card>
          </Stack>
          {dialog.meta.steps.map((step) => {
            const labelItem = step.items.find(
              (item) => item.type === 'DialogHeadline',
            );
            const valueItem = step.items.find((item) =>
              isFormFieldElementType(item.type),
            );

            return (
              <Stack key={step.id}>
                <Legend>{step.name}</Legend>
                <Card title={labelItem?.value ?? '–'}>
                  {valueItem?.value ?? '–'}
                </Card>
              </Stack>
            );
          })}
        </Stack>
      </Tile>
    </Stack>
  );
};
