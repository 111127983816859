import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import './Filters.scss';

export type FiltersItem = {
  id: string;
  label: string;
  indicator?: ReactNode;
  active?: boolean;
  onClick: () => void;
};

export type FiltersProps = PropsWithChildren<{
  items: FiltersItem[];
}>;

export const Filters: FC<FiltersProps> = ({ items, children }) => {
  return (
    <div className="filters">
      <ul className="filters__items">
        {items.map(({ id, label, active, indicator, onClick }) => (
          <li key={id} className="filters__item">
            <button
              className={classNames('filters__action', {
                'filters__action--active': active,
              })}
              onClick={() => onClick()}
            >
              <span className="filters__label">{label}</span>
              {indicator ? (
                <span className="filters__indicator">{indicator}</span>
              ) : null}
            </button>
          </li>
        ))}
      </ul>
      <div className="filters__content">{children}</div>
    </div>
  );
};
