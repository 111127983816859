import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import './Tile.scss';

export type TileProps = PropsWithChildren<{
  variant?: 'blue' | 'pink' | 'orange' | 'neutral' | 'plain';
  title?: ReactNode;
  actions?: ReactNode;
  value?: ReactNode;
  description?: ReactNode;
}>;

export const Tile: FC<TileProps> = ({
  variant = 'plain',
  title,
  actions,
  value,
  description,
  children,
}) => {
  return (
    <div className={classNames('tile', `tile--${variant}`)}>
      {title || actions ? (
        <div className="tile__header">
          <div className="tile__title">{title || null}</div>
          <div className="tile__actions">{actions}</div>
        </div>
      ) : null}
      <div className="tile__content">
        {value ? <div className="tile__value">{value}</div> : null}
        {children}
        {description ? (
          <div className="tile__description">{description}</div>
        ) : null}
      </div>
    </div>
  );
};
