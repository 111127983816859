import { FC } from 'react';
import { WorkflowBuilderInternalWorkflowDialogElement } from './types';
import { DialogElementPreview } from './DialogElementPreview';

import './DialogPreview.scss';

const BUTTON_TYPES = [
  'DialogButton',
  'DialogButtonSchließen',
  'DialogButtonSenden',
];
const PAGINATION_TYPES = ['DialogBlätternWeiter', 'DialogBlätternZurück'];

export type DialogPreviewProps = {
  elements: WorkflowBuilderInternalWorkflowDialogElement[];
};

export const DialogPreview: FC<DialogPreviewProps> = ({ elements }) => {
  const left = elements.filter(
    (element) =>
      element.type === 'DialogCommentBox' && element.alignment === 'LEFT',
  );
  const right = elements.filter(
    (element) =>
      element.type === 'DialogCommentBox' && element.alignment === 'RIGHT',
  );
  const buttons = elements.filter((element) =>
    BUTTON_TYPES.includes(element.type),
  );
  const pagination = elements.filter((element) =>
    PAGINATION_TYPES.includes(element.type),
  );
  const rest = elements.filter(
    (element) =>
      element.type !== 'DialogCommentBox' &&
      !BUTTON_TYPES.includes(element.type) &&
      !PAGINATION_TYPES.includes(element.type),
  );

  return (
    <div className="dialog-preview">
      {left.length ? (
        <div className="dialog-preview__left">
          {left.map((element) => (
            <div key={element.id} className="dialog-preview__element">
              <DialogElementPreview element={element} />
            </div>
          ))}
        </div>
      ) : null}
      <div className="dialog-preview__main">
        {rest.length ? (
          <div className="dialog-preview__form">
            {rest.map((element) => (
              <div key={element.id} className="dialog-preview__element">
                <DialogElementPreview element={element} />
              </div>
            ))}
          </div>
        ) : null}
        {buttons.length ? (
          <div className="dialog-preview__buttons">
            {buttons.map((element) => (
              <div key={element.id} className="dialog-preview__element">
                <DialogElementPreview element={element} />
              </div>
            ))}
          </div>
        ) : null}
        {pagination.length ? (
          <div className="dialog-preview__pagination">
            {pagination.map((element) => (
              <div key={element.id} className="dialog-preview__element">
                <DialogElementPreview element={element} />
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {right.length ? (
        <div className="dialog-preview__left">
          {right.map((element) => (
            <div key={element.id} className="dialog-preview__element">
              <DialogElementPreview element={element} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
