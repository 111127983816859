import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { IconName, getIcon } from './Icon';
import { useSkeleton } from '../utils/context';

import './Navigation.scss';

export type NavigationItem = {
  id: string;
  icon: IconName;
  label: string;
  to: string;
  indicator?: number;
};

export type NavigationProps = {
  items: NavigationItem[];
};

export const Navigation: FC<NavigationProps> = ({ items }) => {
  const { collapsed } = useSkeleton();

  return (
    <nav
      className={classNames('navigation', {
        'navigation--collapsed': collapsed,
      })}
    >
      <ul className="navigation__items">
        {items.map(({ id, icon, label, to, indicator }) => {
          const IconComponent = icon && getIcon(icon);

          return (
            <li key={id} className="navigation__item">
              <NavLink
                to={to}
                className={({ isActive }) =>
                  classNames('navigation__link', {
                    'navigation__link--active': isActive,
                  })
                }
              >
                <span className="navigation__icon">
                  <IconComponent />
                </span>
                <span className="navigation__label">{label}</span>
                {indicator ? (
                  <span className="navigation__indicator">{indicator}</span>
                ) : null}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
