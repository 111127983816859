import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import './Tabs.scss';

export type TabsItem = {
  id: string;
  label: string;
  to?: string;
  onClick?: () => void;
  active?: boolean;
};

export type TabsProps = {
  items: TabsItem[];
};

export const Tabs: FC<TabsProps> = ({ items }) => {
  return (
    <nav className="tabs">
      <ul className="tabs__items">
        {items.map(({ id, label, to, onClick, active }) => (
          <li key={id} className="tabs__item">
            {to ? (
              <NavLink
                end
                to={to}
                className={({ isActive }) =>
                  classNames('tabs__link', {
                    'tabs__link--active': isActive,
                  })
                }
              >
                <span className="tabs__label">{label}</span>
              </NavLink>
            ) : (
              <button
                className={classNames('tabs__link', {
                  'tabs__link--active': active,
                })}
                onClick={() => onClick?.()}
              >
                <span className="tabs__label">{label}</span>
              </button>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
