import { FC } from 'react';
import {
  WorkflowBuilderInternalWorkflowDialog,
  WorkflowBuilderInternalWorkflowParty,
  WorkflowBuilderInternalWorkflowStep,
} from './types';
import { VisualizerParty } from './VisualizerParty';
import {
  VisualizerDivider,
  getVisualizerDividerVariantForIndex,
} from './VisualizerDivider';
import { bus } from '../../../utils/bus';

import './VisualizerStep.scss';

export type VisualizerStepProps = {
  step: WorkflowBuilderInternalWorkflowStep;
  party?: WorkflowBuilderInternalWorkflowParty;
  dialog?: WorkflowBuilderInternalWorkflowDialog;
};

export const VisualizerStep: FC<VisualizerStepProps> = ({
  step,
  party,
  dialog,
}) => {
  return (
    <div className="visualizer-step" title={step.name}>
      <button
        className="visualizer-step__shape"
        onClick={() => {
          bus.emit('workflow-builder:step', {
            stepId: step.id,
          });
        }}
      >
        {step.name}
      </button>
      <div className="visualizer-step__parties">
        {step.parties
          .filter((partyItem) => !party || partyItem.id === party.id)
          .map((party, index, array) => (
            <div key={party.id} className="visualizer-step__party">
              <VisualizerDivider
                variant={getVisualizerDividerVariantForIndex(index, array)}
              />
              <VisualizerParty party={party} dialog={dialog} />
            </div>
          ))}
      </div>
    </div>
  );
};
