import { FC } from 'react';
import { Button } from '../../components/Button';
import { FormField } from '../../components/FormField';
import { Stack } from '../../components/Stack';
import { ContractRelation } from '../../services/api/contracts';
import { Choice } from '../../components/Choice';
import { useKeyPress } from '../../utils/hooks';

export type ContractRelationFormProps = {
  currentContractRelationId: string | undefined;
  setCurrentContractRelationId: (newContractRelationId: string) => void;
  contractRelations: ContractRelation[];
  onSubmit: () => void;
};

export const ContractRelationForm: FC<ContractRelationFormProps> = ({
  currentContractRelationId,
  setCurrentContractRelationId,
  contractRelations,
  onSubmit,
}) => {
  useKeyPress('Enter', onSubmit);

  return (
    <Stack>
      <FormField id="contract-relation" label="Vertragsbeziehung">
        <Stack>
          {contractRelations.map((contractRelation) => (
            <Choice
              key={contractRelation.id}
              label={contractRelation.label}
              shortcut={contractRelation.label.substr(0, 1)}
              selected={contractRelation.id === currentContractRelationId}
              onClick={() => setCurrentContractRelationId(contractRelation.id)}
            />
          ))}
        </Stack>
      </FormField>
      <Button
        disabled={!currentContractRelationId}
        label="Weiter"
        shortcut="Enter"
        onClick={onSubmit}
      />
    </Stack>
  );
};
