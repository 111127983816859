import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { User } from '../services/api/model';
import { getRights, getToken, getUser } from '../services/auth';

type AuthApi = {
  token: string | undefined;
  user: User | undefined;
  rights: string[];
  handleLogin: (newToken: string, newUser: User, newRights: string[]) => void;
  handleLogout: () => void;
};

const AuthContext = createContext<AuthApi>({
  token: undefined,
  user: undefined,
  rights: [],
  handleLogin: () => undefined,
  handleLogout: () => undefined,
});

type AuthProviderProps = PropsWithChildren<{}>;

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | undefined>(getToken());
  const [user, setUser] = useState<User | undefined>(getUser());
  const [rights, setRights] = useState<string[]>(getRights() ?? []);

  const handleLogin = async (
    newToken: string,
    newUser: User,
    newRights: string[],
  ) => {
    setToken(newToken);
    setUser(newUser);
    setRights(newRights);
  };

  const handleLogout = () => {
    setToken(undefined);
    setUser(undefined);
    setRights([]);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        rights,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthApi => useContext(AuthContext);
