import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import './Badge.scss';

export type BadgeProps = {
  variant?: 'primary' | 'positive' | 'negative' | 'warning' | 'neutral';
  label?: ReactNode;
};

export const Badge: FC<BadgeProps> = ({ variant = 'neutral', label }) => {
  return (
    <div className={classNames('badge', `badge--${variant}`)}>{label}</div>
  );
};
