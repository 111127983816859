export const isFormFieldElementType = (type: string): boolean =>
  Boolean(
    type &&
      ['DialogTextField', 'DialogDatePicker', 'DialogPersonPicker'].includes(
        type,
      ),
  );

export const isChoiceElementType = (type: string): boolean =>
  Boolean(type && ['DialogChoice'].includes(type));

export const isUploadFieldElementType = (type: string): boolean =>
  Boolean(type && ['DialogFileUpload'].includes(type));

export const isButtonElementType = (type: string): boolean =>
  Boolean(type && ['DialogButton'].includes(type));

export const isReadOnlyElementType = (type: string): boolean =>
  Boolean(type && ['DialogCommentBox', 'DialogFileDownload'].includes(type));

export const isLinkingElement = (type: string): boolean =>
  isButtonElementType(type) || isChoiceElementType(type);
