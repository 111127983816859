export type Nullable<T> = T | null;

export type Mapped<T> = {
  map: T;
};

export type ErrorResponse = {
  message: 'Invalid Token!';
};

export const isErrorResponse = (response: unknown): response is ErrorResponse =>
  typeof response === 'object' &&
  response !== null &&
  'message' in response &&
  typeof response.message === 'string' &&
  response.message === 'Invalid Token!';

export type ApiResponse<T> = ErrorResponse | T;

export const isValidApiResponse = <T>(
  response: ErrorResponse | T,
): response is T => !isErrorResponse(response);

export type WithIds<T> = {
  _idAsString: string;
  uuid: string;
} & T;
